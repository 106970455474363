import { doc, Firestore, FirestoreDataConverter, getDoc, onSnapshot } from "firebase/firestore";
import { Observable } from "rxjs";
import { GuidInterface } from "src/app/domain/util/guid";
import { FirebaseGuid } from "./firebase.guid";

export abstract class FirebaseCollection<T> {

    constructor(
        protected _firestore: Firestore,
        protected _converter: FirestoreDataConverter<T>
    ) { }

    observe(guid: FirebaseGuid): Observable<T> {
        const id = guid.toString();
        const ref = doc(this._firestore, id)
            .withConverter(this._converter);

        return new Observable<T>(sub => {
            const unsub = onSnapshot(ref,
                (doc) => {
                    sub.next(doc.exists ? doc.data() : null);
                },
                (error) => sub.error(error),
                () => unsub());
        })
    }

    get(guid: GuidInterface): Observable<T> {
        return new Observable<T>(sub => {
            const id = guid.toString();
            const ref = doc(this._firestore, id)
                .withConverter(this._converter);

            getDoc(ref)
                .then(snap => {
                    let data = null;
                    if (snap.exists())
                        data = snap.data();
                    sub.next(data);
                })
                .catch(e => sub.error(e))
        });
    }


}
