import { DAY_OF_WEEK } from "src/app/app.constants";
import { clone } from "../util/clone.fn";
import { Guid, GuidInterface, GuidKeys } from "../util/guid";
import { notUndefined } from "../util/not-undefined";

export type TipoLeitura = 'todos' | 'cestou-qrcode' | 'qrcode';
export type TipoEntrada = 'todos' | 'leitor' | 'texto';

export type CardapioHorarioAttr = {
    diaSemana: string;
    inicio: string;
    termino: string;
};

export type CardapioAttr = {
    organizacaoId: string;
    accessPoint: string;
    enabled: boolean;

    horarios: CardapioHorarioAttr[];
    gerarFichaAutomaticamente: boolean;
    obrigatorioInformarFicha: boolean;
    obrigatorioInformarMesa: boolean;
    tipoEntradaFicha: TipoEntrada;
    tipoEntradaMesa: TipoEntrada;
    tipoLeituraFicha: TipoLeitura;
    tipoLeituraMesa: TipoLeitura;
    utilizarFicha: boolean;
    utilizarMesa: boolean;
    utilizarHorarioDeFuncionamento: boolean;
    utilizarPedido: boolean;
    utilizarEstoque: boolean;
    enviarPedidoPeloWhats: boolean;
}

export interface CardapioSetters {
}


export interface CardapioInterface extends GuidInterface {
    get id(): string;
    get organizacaoId(): string;
    get accessPoint(): string;

    get enabled(): boolean;
    get obrigatorioInformarFicha(): boolean;
    get obrigatorioInformarMesa(): boolean;
    get tipoEntradaFicha(): TipoEntrada;
    get tipoEntradaMesa(): TipoEntrada;
    get tipoLeituraFicha(): TipoLeitura;
    get tipoLeituraMesa(): TipoLeitura;
    get utilizarFicha(): boolean;
    get utilizarMesa(): boolean;
    get gerarFichaAutomaticamente(): boolean;
    get horarios(): CardapioHorarioAttr[];
    get utilizarHorarioDeFuncionamento(): boolean;
    get utilizarPedido(): boolean;
    get utilizarEstoque(): boolean;
    get enviarPedidoPeloWhats(): boolean;

    set enabled(value: boolean);
    set obrigatorioInformarFicha(value: boolean);
    set obrigatorioInformarMesa(value: boolean);
    set tipoEntradaFicha(value: TipoEntrada);
    set tipoEntradaMesa(value: TipoEntrada);
    set tipoLeituraFicha(value: TipoLeitura);
    set tipoLeituraMesa(value: TipoLeitura);
    set utilizarFicha(value: boolean);
    set utilizarMesa(value: boolean);
    set gerarFichaAutomaticamente(value: boolean);
    set utilizarHorarioDeFuncionamento(value: boolean);
    set utilizarPedido(value: boolean);
    set utilizarEstoque(value: boolean);
    set enviarPedidoPeloWhats(value: boolean);

    addHorario(horario: CardapioHorarioAttr): void;
    delHorario(diaSemana: string): void;

    update(values: Partial<CardapioSetters>): void;
    isOpened(date: Date): boolean;
}


export class Cardapio implements CardapioInterface {

    private readonly _attr: CardapioAttr;
    private readonly _guid: GuidInterface;

    constructor(attr: CardapioAttr, guid: Guid) {
        this._attr = attr;
        this._guid = guid;
    }
    get gerarFichaAutomaticamente(): boolean {
        return this._attr.gerarFichaAutomaticamente;
    }
    set gerarFichaAutomaticamente(value: boolean) {
        this._attr.gerarFichaAutomaticamente = value;
    }
    get horarios(): CardapioHorarioAttr[] {
        return clone(this._attr.horarios ?? []);
    }

    get utilizarHorarioDeFuncionamento(): boolean {
        return this._attr.utilizarHorarioDeFuncionamento ?? false;
    }
    set utilizarHorarioDeFuncionamento(value: boolean) {
        this._attr.utilizarHorarioDeFuncionamento = value;
    }
    get utilizarPedido(): boolean {
        return this._attr.utilizarPedido ?? false;
    }

    set utilizarPedido(value: boolean) {
        this._attr.utilizarPedido = value;
    }

    get utilizarEstoque():boolean{
        return this._attr.utilizarEstoque ?? false;
    }

    set utilizarEstoque(value:boolean){
        this._attr.utilizarEstoque = value ?? false;
    }

    set enviarPedidoPeloWhats(value:boolean){
        this._attr.enviarPedidoPeloWhats = value ?? true;
    }

    get enviarPedidoPeloWhats(){
        return this._attr.enviarPedidoPeloWhats ?? true;
    }

    get id(): string {
        return this._guid.id;
    }

    get keys(): GuidKeys {
        return Object.assign({}, this._guid.keys);
    }

    get organizacaoId(): string {
        return this._attr.organizacaoId;
    }

    get accessPoint(): string {
        return this._attr.accessPoint;
    }
    get enabled(): boolean {
        return this._attr.enabled ?? false;
    }

    get obrigatorioInformarFicha(): boolean {
        return this._attr.obrigatorioInformarFicha;
    }

    get obrigatorioInformarMesa(): boolean {
        return this._attr.obrigatorioInformarMesa;
    }

    get tipoEntradaFicha(): TipoEntrada {
        return this._attr.tipoEntradaFicha;
    }

    get tipoEntradaMesa(): TipoEntrada {
        return this._attr.tipoEntradaMesa;
    }

    get tipoLeituraFicha(): TipoLeitura {
        return this._attr.tipoLeituraFicha;
    }

    get tipoLeituraMesa(): TipoLeitura {
        return this._attr.tipoLeituraMesa;
    }

    get utilizarFicha(): boolean {
        return this._attr.utilizarFicha;
    }

    get utilizarMesa(): boolean {
        return this._attr.utilizarMesa;
    }

    set enabled(value: boolean) {
        this._attr.enabled = value;
    }
    set obrigatorioInformarFicha(value: boolean) {
        this._attr.obrigatorioInformarFicha = value;
    }
    set obrigatorioInformarMesa(value: boolean) {
        this._attr.obrigatorioInformarMesa = value;
    }
    set tipoEntradaFicha(value: TipoEntrada) {
        this._attr.tipoEntradaFicha = value;
    }
    set tipoEntradaMesa(value: TipoEntrada) {
        this._attr.tipoEntradaMesa = value;
    }
    set tipoLeituraFicha(value: TipoLeitura) {
        this._attr.tipoLeituraFicha = value;
    }
    set tipoLeituraMesa(value: TipoLeitura) {
        this._attr.tipoLeituraMesa = value;
    }
    set utilizarFicha(value: boolean) {
        this._attr.utilizarFicha = value;
    }
    set utilizarMesa(value: boolean) {
        this._attr.utilizarMesa = value;
    }

    addHorario(horario: CardapioHorarioAttr): void {
        this._attr.horarios.add(horario, h => h.diaSemana === horario.diaSemana);
    }

    delHorario(diaSemana: string): void {
        this._attr.horarios.del(h => h.diaSemana === diaSemana)
    }

    isOpened(date: Date): boolean {
        if (!this.enabled)
            return false;

        if (!this.utilizarPedido) {
            return false;
        }

        if (!this.utilizarHorarioDeFuncionamento) {
            return true;
        }

        const d = date.getDay();
        const h = date.getHours();
        const m = date.getMinutes();
        const t1 = h * 60 + m;

        const t2n = (time: string): number => {
            if (!time) { return 0; }
            const s = time.split(':').map(a => +a);
            return s[0] * 60 + s[1];
        }

        const result = this.horarios
            .filter(a => a.diaSemana === DAY_OF_WEEK[d].type)
            .some(a => t1 >= t2n(a.inicio) && t1 <= t2n(a.termino));

        return result;
    }

    update(values: Partial<CardapioSetters>) {
        const clonedValues = clone(values);
        const attr = notUndefined(clonedValues);
        const attrKeys = Object.keys(attr);
        Object.keys(this)
            .filter(k => attrKeys.includes(k))
            .map(k => this[k] = attr[k]);
    }

}
