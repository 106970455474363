import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxCoreModule } from '@azoup/ngx-core';
import { NgxFirebaseService } from '@azoup/ngx-firebase';
import { NgxFirebaseAuthService } from '@azoup/ngx-firebase-auth';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AppService } from './app.service';
import { CoreModule } from './core/core.module';
import { FirebaseModule } from './infra/firebase/firebase.module';
import { LocalStorageModule } from './infra/local-storage/local-storage.module';
import { LogRepository } from './domain/entities/log/log.repository';
import { LogApiRepository } from './infra/log-api/log-api.repository';
import { AuthService } from './modules/auth/auth.service';
import { SharedModule } from './shared/shared.module';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { UtilService } from './_services/util.service';



registerLocaleData(localePt);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        //----
        LocalStorageModule.forRoot({ storage: 'local' }),
        FirebaseModule.forRoot(environment.firebase.config),
        NgxCoreModule,
        UiComponentsModule,
        MatDialogModule,
        MatSnackBarModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        AppService,
        MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
        MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: MAT_DATE_LOCALE, useValue: 'pt' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill', floatLabel: 'always' } },
        { provide: NgxFirebaseService, useFactory: () => new NgxFirebaseService(environment.firebase.config) },
        { provide: NgxFirebaseAuthService },
        { provide: LogRepository, useClass: LogApiRepository },
        // TODO: ANALISAR OS PROVIDERS
        { provide: NgxFirebaseAuthService },
        { provide: MatSnackBar },
        AuthService,
        UtilService,
        CurrencyPipe,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
