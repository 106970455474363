<div class='mask'>
    <div class='message' *ngIf='message'>{{message}}</div>
    <div class='target' [ngStyle]='targetStyle'>
        <mat-icon>crop_free</mat-icon>
    </div>
    <div class='footer'>
        <button mat-icon-button class='btn-torch' (click)='onClickCloseCamera()'>
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-icon-button class='btn-torch' (click)='onClickSwitchTorch()'>
            <mat-icon>{{torch ? 'flash_on' : 'flash_off'}}</mat-icon>
        </button>
        <button mat-icon-button class='btn-switch' (click)='onClickSwitchCamera()' *ngIf="(scanners?.length ??0)>1">
            <mat-icon>switch_camera</mat-icon>
        </button>
    </div>
</div>
<video id="preview" #preview width="100%" height="100%"  willReadFrequently="true"></video>
