<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
    <p *ngIf="data.message">{{data.message}}</p>
    <mat-list>
        <mat-list-item *ngFor="let i of data.list">
            <mat-icon class='modal-list-icon' matListIcon [svgIcon]="i.icon" *ngIf='i.icon'></mat-icon>
            <h3 matLine> {{i.label}} </h3>
            <p matLine *ngIf='i.description'>{{i.description}}</p>
            <mat-slide-toggle color='primary' [checked]='i.selected' (click)="onClick(i)"></mat-slide-toggle>
        </mat-list-item>
    </mat-list>
</mat-dialog-content>
<mat-dialog-actions>
    <button *ngIf='data.cancel' mat-button (click)='onClickCancel()'>{{data.cancel.label}}</button>
    <button mat-raised-button (click)='onClickConfirm()' color='orange'>{{data.confirm.label}}</button>
</mat-dialog-actions>
