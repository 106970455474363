import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { IconService, SnackService } from '@azoup/ngx-ui';
import { User } from 'firebase/auth';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import { filter, Observable, Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { APP_FLAG_ICONS, APP_ICONS } from './app.constants';
import { FirebaseAuthService } from './infra/firebase/firebase.module';

import { Util } from './_services/util.service';

@Injectable({ providedIn: 'root' })
export class AppService {

    private initialized = false;
    private currentUser: User | null = null;

    constructor(
        private firebaseAuth: FirebaseAuthService,
        private authService: AuthService,
        private icons: IconService,
        private router: Router,
        private swUpdate: SwUpdate,
        private snack: SnackService,
        private mediaMatcher: MediaMatcher,
    ) { }

    async init(destroy: Subject<void>): Promise<void> {
        if (this.initialized) {
            return Promise.resolve();
        }

        this.firebaseAuth.observe()
            .pipe(takeUntil(destroy))
            .subscribe(user => {
                this.currentUser = !user ? null : user
            });


        await Promise.all([
            this.checkNewVersion(),
            this.loadMoment(),
            this.loadIcons(),
            this.authService.init(),
            this.firebaseAuth.init(),
        ]);

        this.initialized = true;
    }

    private async loadIcons(): Promise<void> {
        Object.values(APP_ICONS).forEach(i => this.icons.addSvgIcon(i));
        Object.values(APP_FLAG_ICONS).forEach(i => this.icons.addSvgIcon(i));
    }

    private async loadMoment(): Promise<void> {
        moment.tz.setDefault('America/Sao_Paulo');
    }

    public observeNavigation() {
        return this.router.events
            .pipe(
                filter(event =>
                    event instanceof NavigationStart
                    || event instanceof NavigationCancel
                    || event instanceof NavigationEnd
                    || event instanceof NavigationError
                )
            );
    }

    private async checkNewVersion(): Promise<void> {
        if (!this.swUpdate.isEnabled) return;

        const hasUpdate = await Promise.race([
            this.swUpdate.checkForUpdate(),
            new Promise(resolve => setTimeout(() => resolve(false), 6000)),
        ]);

        if (!hasUpdate)
            return;

        const updated = await this.swUpdate.activateUpdate();

        if (updated)
            return;

        alert('New version allready!')
        window.location.reload();
        await Util.sleep(100000);
    }

    public observeMediaQuery(query: string) :Observable<boolean>{
        const mediaQueryMobile = this.mediaMatcher.matchMedia(query);
        return new Observable(subject => {
            mediaQueryMobile.addEventListener('change', listener => subject.next(listener.matches));
            subject.next(mediaQueryMobile.matches);
        });

    }

    public getCurrentUser(): User | null {
        return this.currentUser;
    }
}
