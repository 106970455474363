import { Injectable } from "@angular/core";
import CryptoJS from 'crypto-js';

export type EncryptType = 'AES' | 'Base64' | 'MD5';
export type DecryptType = 'AES' | 'Base64';

@Injectable({ providedIn: 'root' })
export class CryptoService {

    public static readonly SECRET_DEFAULT = '#############cestou#############';


    public decrypt(encryptedDataHexStr: string, secret: string = CryptoService.SECRET_DEFAULT): string {
        const key = CryptoJS.enc.Utf8.parse(secret);
        const fooDecrypted2 = CryptoJS.AES.decrypt(encryptedDataHexStr, key, { mode: CryptoJS.mode.ECB, format: CryptoJS.format.Hex });
        return CryptoJS.enc.Utf8.stringify(fooDecrypted2);
    }


    public encrypt(plainText: string, secret: string = CryptoService.SECRET_DEFAULT): string {
        const key = CryptoJS.enc.Utf8.parse(secret);
        return CryptoJS.AES.encrypt(plainText, key, { mode: CryptoJS.mode.ECB, format: CryptoJS.format.Hex }).toString();
    }


    public md5(plainText: string): string {
        const hash = CryptoJS.MD5(CryptoJS.enc.Utf8.parse(plainText));
        const md5 = hash.toString(CryptoJS.enc.Hex)
        return md5;
    }
}
