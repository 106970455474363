import { Directive, EventEmitter, HostListener, Output } from '@angular/core';


@Directive({
    selector: '[touch]',
})
export class TouchDirective {

    @Output('left') leftEvent: EventEmitter<[Event, Event]> = new EventEmitter();
    @Output('right') rightEvent: EventEmitter<[Event, Event]> = new EventEmitter();

    private start: TouchEvent | null = null;
    private end: TouchEvent | null = null;

    constructor() { }

    @HostListener('touchstart', ['$event'])
    public onTouchStart(event: TouchEvent): void {
        this.start = event;
        this.end = null;
        this.emitEvent();
    }

    @HostListener('touchend', ['$event'])
    public onTouchEnd(event: TouchEvent): void {
        this.end = event;
        this.emitEvent();
    }

    private emitEvent() {
        if (this.start === null || this.start === undefined)
            return;

        if (this.end === null || this.end === undefined)
            return;

        const x1 = this.start.touches[0].pageX;
        const x2 = this.end.changedTouches[0].pageX;
        if (Math.abs(x1 - x2) > 40) {
            if (x1 > x2)
                this.leftEvent.emit([this.start, this.end]);
            else if (x1 < x2)
                this.rightEvent.emit([this.start, this.end]);
        }
    }


}
