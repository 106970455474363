import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { ErrorApiService } from './errors/error-api.service';
import { ErrorDialogService } from './errors/error-dialog.service';
import { ErrorDialogComponent } from './errors/error-dialog/error-dialog.component';


const sharedComponents = [ErrorDialogComponent];

@NgModule({
    declarations: [...sharedComponents],
    imports: [
        CommonModule,
        RouterModule,
        // ----
        MatButtonModule,
        MatDialogModule,
    ],
    providers: [
        ErrorApiService,
        ErrorDialogService,
    ],
    exports: [...sharedComponents],
    entryComponents: [...sharedComponents],
})
export class SharedModule { }
