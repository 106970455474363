<h1 mat-dialog-title>Algo inesperado aconteceu!</h1>
<div mat-dialog-content>
    <p *ngIf="data.status">
        <b>Status Code: {{ data.status }}</b>
    </p>
    <p>Alguma coisa está errado:</p>
    <p class="error-message">
        {{ data?.message }}
    </p>
</div>
<div mat-dialog-actions>
    <button mat-raised-button mat-dialog-close color="primary">Fechar</button>
</div>
