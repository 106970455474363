import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loading-tile',
  templateUrl: './loading-tile.component.html',
  styleUrls: ['./loading-tile.component.scss']
})
export class LoadingTileComponent implements OnInit, OnDestroy {

  constructor(
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {

  }


}
