import { Injectable } from '@angular/core';
import { NgxFirebaseCollection, NgxFirebaseDoc, NgxFirebaseService } from '@azoup/ngx-firebase';
import { OrganizacaoDoc } from './organizacao.doc';
import { OrganizacaoSchema } from './organizacao.schema';
import { OrganizacaoUsuarioDoc } from './usuario/organizacao-usuario.doc';
import { OrganizacaoUsuarioSchema } from './usuario/organizacao-usuario.schema';

@Injectable({ providedIn: 'root' })
export class OrganizacaoCollection extends NgxFirebaseCollection<OrganizacaoSchema> {

    private firebase: NgxFirebaseService;

    constructor(fb: NgxFirebaseService) {
        super('/organizacao', fb, (snap) => new OrganizacaoDoc(snap) as NgxFirebaseDoc<OrganizacaoSchema>);
        this.firebase = fb;
    }

    async getUsuario(organizacaoId: string, userKey: string) {
        return this.collection.doc(organizacaoId).collection('/usuario')
            .doc(userKey)
            .get().then(snaps => snaps.exists ? new OrganizacaoUsuarioDoc(snaps) : null);
    }

    async getUsuarioByEmail(organizacaoId: string, email: string) {
        return this.collection.doc(organizacaoId).collection('/usuario')
            .where('email', '==', email).get()
            .then(snaps => (snaps.docs || []).map(snap => new OrganizacaoUsuarioDoc(snap)))
    }

    async addUsuario(organizacaoId: string, raw: OrganizacaoUsuarioSchema) {
        (raw as any).cadastro = this.firebase.serverTimestamp();
        Object.keys(raw).forEach(k => raw[k] === undefined && delete raw[k]);
        return this.collection.doc(organizacaoId)
            .collection('/usuario')
            .doc().set(raw)
    }

    async deleteUsuario(organizacaoId: string, usuarioId: string): Promise<void> {
        return this.collection.doc(organizacaoId)
            .collection('/usuario').doc(usuarioId).delete();
    }

    async getUsuarios(organizacaoKey: string, equals: { [key: string]: any }) {
        let query = this.collection.doc(organizacaoKey).collection('/usuario');
        if (equals) {
            Object.keys(equals).map(k => {
                query = query.where(k, '==', equals[k]) as any;
            });
        }
        return query.get().then(snaps => snaps.empty ? [] : snaps.docs.map(d => new OrganizacaoUsuarioDoc(d)));
    }

    async getGestores(organizacaoKey: string) {
        return this.collection.doc(organizacaoKey).collection('/usuario')
            .where('roles.gestor', '==', true)
            .get().then(snaps => snaps.empty ? null : snaps.docs.map(d => new OrganizacaoUsuarioDoc(d)));
    }

    profissionais(organizacaoKey: string) {
        const query = this.collection.doc(organizacaoKey).collection('/usuario')
            .where('roles.profissional', '==', true);
        return this.execute(query);
    }

    clientes(organizacaoKey: string) {
        const query = this.collection.doc(organizacaoKey).collection('/usuario')
            .where('roles.clientes', '==', true);
        return this.execute(query);
    }

    async exist(id: string): Promise<boolean> {
        return (await this.collection.doc(id).get()).exists;
    }

    async getUsuariosFromCollectionGroupUsuarios(equals: any = { inativo: false }) {
        const collectionGroup = this.fb.db.collectionGroup('usuario');
        equals.inativo = (equals.inativo || false);
        const query = this.equal(equals, collectionGroup).query();
        return query.get().then(snaps => (snaps.docs || []).map(s => new OrganizacaoUsuarioDoc(s)));
    }


}
