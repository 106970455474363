import { Log } from "./log.model";


export type LogPagination = { page: number, itens: number };
export type LogSearchFilter = {
    organizacao?: string,
    levels?: string[],
    inicio?: Date,
    termino?: Date,
};

export abstract class LogRepository {
    abstract save(log: Log): Promise<void>;
}
