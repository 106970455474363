import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CardapioInterface } from "src/app/domain/entities/cardapio.model";
import { CardapioRepository as CardapioRepositoryInterface } from "src/app/domain/entities/cardapio.repository";
import { GuidInterface } from "src/app/domain/util/guid";
import { CardapioCollection } from "src/app/infra/firebase/cardapio/cardapio.collection";

@Injectable({ providedIn: 'root', useClass: CardapioCollection })
export abstract class CardapioRepository implements CardapioRepositoryInterface {
    abstract get(id: GuidInterface): Observable<CardapioInterface>;
    abstract save(model: CardapioInterface): Promise<void>;
}
