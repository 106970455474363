
export type LogLevel =
    | 'trace'
    | 'debug'
    | 'information'
    | 'warning'
    | 'error'
    | 'critical'
    ;

export type LogAttr = {
    level: LogLevel,
    evento: string,
    message: string,
    registro: string,
    organizacao: string,
    aplicacaoNome: string,
    aplicacaoVersao: string,
    hash: string,
}

export interface LogInterface {
    get level(): LogLevel;
    get message(): string;
    get evento(): string;
    get organizacao(): string;
    get aplicacaoNome(): string;
    get aplicacaoVersao(): string;
    get hash(): string;
    levelCode(): number;
}

export class Log implements LogInterface {

    private readonly _attr: LogAttr;

    constructor(attr: LogAttr) {
        this._attr = attr;
    }

    public get organizacao(): string { return this._attr.organizacao; }
    public get evento(): string { return this._attr.evento; }
    public get level(): LogLevel { return this._attr.level; }
    public get message(): string { return this._attr.message; }
    public get registro(): string { return this._attr.registro; }
    public get aplicacaoNome(): string { return this._attr.aplicacaoNome; }
    public get aplicacaoVersao(): string { return this._attr.aplicacaoVersao; }
    public get hash(): string { return this._attr.hash; }


    public levelCode(): number {
        switch (this._attr.level) {
            case 'trace': return 0;
            case 'debug': return 1;
            case 'information': return 2;
            case 'warning': return 3;
            case 'error': return 4;
            case 'critical': return 5;
            default:
                throw `level '${this._attr.level} not implemented`
        }
    }


}
