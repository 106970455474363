import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LocalStorageConfig } from './local-storage.config';
import { LocalStorageConfigService, LocalStorageService } from './local-storage.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
    ],
})
export class LocalStorageModule {
    static forRoot(config: LocalStorageConfig): ModuleWithProviders<LocalStorageModule> {
        return {
            ngModule: LocalStorageModule,
            providers: [
                { provide: LocalStorageConfigService, useValue: config },
                LocalStorageService,
            ]
        };
    }
}
