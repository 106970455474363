import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Result } from '@zxing/library';
import { Throttle } from 'lodash-decorators';
import { Subject, takeUntil } from 'rxjs';
import { CodeReaderResult, QrCodeReaderService, ScannerControl } from './qrcode-reader.service';

export type TQRcodeResultJson = {
    id: string;
    organizacaoId: string;
    mesa: string;
    ficha: string;
    createAt: Date;
    expireAt: Date;
};


@Component({
    selector: 'app-qrcode-reader',
    templateUrl: './qrcode-reader.component.html',
    styleUrls: ['./qrcode-reader.component.scss']
})
export class QrcodeReaderComponent implements OnInit, AfterViewInit, OnDestroy {

    message: string;
    targetStyle: { color: string } = { color: 'rgba(255,255,255,0.25)' };
    torch: boolean;

    @ViewChild('preview')
    private preview: ElementRef;
    private lastResultText: string;

    @Output()
    data: EventEmitter<Result> = new EventEmitter();

    @Output()
    close: EventEmitter<void> = new EventEmitter();


    scanners: ScannerControl[];
    private scanner: ScannerControl;
    private scannerIndex: number;
    private _destroyed = new Subject<void>();

    constructor(
        private codeReaderService: QrCodeReaderService,
        private dialog: MatDialog,
    ) { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.checkCameraPermission();
    }

    ngOnDestroy(): void {
        this.closeCamera();
        this._destroyed.next();
        this._destroyed.complete();
    }

    private async checkCameraPermission(): Promise<PermissionState> {
        return this.codeReaderService.permission()
            .then(state => {
                switch (state) {
                    case 'denied':
                        this.showDeniedMessage();
                        break;
                    case 'granted':
                        this.openCamera()
                            .catch(() => this.checkCameraPermission());
                        break;
                    case 'prompt':
                        this.openPrompt();
                        break;
                }
                return state;

            });
    }

    private showDeniedMessage(): void {
        this.message = 'Habilite o acesso a camera!';
        this.targetStyle = { color: 'rgba(255,0,0,1)' };
    }

    private openPrompt(): void {
        const config = new MatDialogConfig();
        const dlg = this.dialog.open(QrCodePromptDialogComponent, config);
        dlg.afterClosed()
            .pipe(takeUntil(this._destroyed))
            .subscribe(r => {
                if (!r) {
                    this.onClickCloseCamera()
                    return;
                }
                this.openCamera()
                    .catch(() => this.checkCameraPermission());
            });
    }

    private async openCamera(): Promise<void> {
        const reader = await this.codeReaderService.reader();
        reader.observe()
            .pipe(takeUntil(this._destroyed))
            .subscribe(r => this.handle(r));

        this.scanners = reader.scanner({ preview: this.preview });
        this.scannerIndex = this.scanners.length - 1;
        this.scanner = await this.scanners[this.scannerIndex].start();

        this.message = this.message || 'Posicione o QR Code no centro da tela';
        this.targetStyle = { color: 'rgba(255,255,255,0.25)' };

    }


    private closeCamera(): void {
        this.scanner?.stop();
        this.scanner = null;
    }


    @Throttle(1000)
    async onClickSwitchTorch(): Promise<void> {
        if (this.scanner) {
            this.torch = !this.torch;
            this.scanner.torch(this.torch);
        }
    }



    @Throttle(1000)
    async onClickCloseCamera(): Promise<void> {
        this.closeCamera();
        this.close.emit();
    }

    @Throttle(1000)
    async onClickSwitchCamera(): Promise<void> {
        if (++this.scannerIndex >= this.scanners.length) {
            this.scannerIndex = 0;
        }

        this.scanner?.stop();
        this.scanner = await this.scanners[this.scannerIndex].start();
        this.scanner.torch(this.torch);
    }

    private handle(r: CodeReaderResult): void {
        this.handleResult(r.result);
        this.handleError(r.error);
    }


    private handleResult(result: Result): void {
        const resultText = result?.getText();
        if (!result || this.lastResultText === resultText) { return; }
        this.lastResultText = resultText;
        this.data.next(result);
    }

    private handleError(error: Error): void {
        if (!error) {
            return;
        }
        switch (error.name) {

            case 'ChecksumException':
                this.message = 'Centralize DENTRO da marcação';
                this.targetStyle = { color: 'rgba(255,50,50,0.25)' };
                break;

            case 'NotFoundException':
            default:
                this.message = this.message || 'Posicione o QR Code no centro da tela';
                this.targetStyle = { color: 'rgba(255,255,255,0.25)' };
                break;
        }

    }

}

@Component({
    selector: 'app-qrcode-reader-prompt-dialog',
    templateUrl: './qrcode-reader-prompt.html',
})
export class QrCodePromptDialogComponent {
    constructor() { }
}
