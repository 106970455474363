import pack from '../../package.json';
export const environment = {
    name: pack.name,
    version: pack.version,
    productName: 'cestou',
    production: true,
    firebase: {
        config: {
            apiKey: "AIzaSyDctb37MNhh0r9lC3n-Ic-VL1v8kR_vY_c",
            authDomain: "az-carrinhodecompras-prod.firebaseapp.com",
            databaseURL: "https://az-carrinhodecompras-prod.firebaseio.com",
            projectId: "az-carrinhodecompras-prod",
            storageBucket: "az-carrinhodecompras-prod.appspot.com",
            messagingSenderId: "415060561736",
            appId: "1:415060561736:web:e22cbc00adec689dfd6d7d",
            measurementId: "G-JFKEF0V5T5"
        }
    },
    tomtom: {
        apiKey: 'x2rDD3ttAsTHdg1yMFKESDRceG70Otd9',
        apiURL: 'https://api.tomtom.com',
        apiVersion: '2'
    },
    openroute: {
        apiKey: '5b3ce3597851110001cf62481934b0d165544b07930b5d76161f114d',
        token: 'az-carrinhodecompras-prod',
        apiURL: 'https://api.openrouteservice.org',
    },
    viacep: {
        apiURL: 'https://viacep.com.br'
    },
    enterprisePlataform: {
        url: 'https://azoup.app'
    },
    log: {
        url: "https://api.log.azoup.app"
    }
};
