import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";

import { Log, LogAttr } from "src/app/domain/entities/log/log.model";
import { ErrorApiService } from "src/app/shared/errors/error-api.service";
import { ErrorDialogService } from "src/app/shared/errors/error-dialog.service";
import { CryptoService } from "src/app/_services/crypto.service";
import { environment } from "src/environments/environment";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private criptoService: CryptoService,
        private errorApiService: ErrorApiService,
        private errorDialogService: ErrorDialogService,
        private route: ActivatedRoute,
        private zone: NgZone
    ) { }

    handleError(error: any) {
        console.error(error);


        /**
         * Falha ao carregar a biblioteca de maneira assincrona.
         * Este erro esta acontecendo quando é liberado uma nova versão
         * da aplicação, trocando assim o hash de identificação da biblioteca.
         *
         * Ao reinciar é carregado a nova biblioteca.
         */
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error?.message)) {
            window.location.reload();
            return;
        }


        /**
         * Este erro ocorre quando não é encontrado o registro 'database-info'
         * no cache do navegador, indicando que não foi iniciado de maneira
         * correta a infraestrutura local-storage.
         *
         * É necessário reiniciar a aplicação para que o servico inicie
         * corretamente o local-storage, recriando o registro 'database-info'
         */
        const databaseInfoNotExistMessage = /database-info\/not-found/;
        if (databaseInfoNotExistMessage.test(error?.message)) {
            window.location.reload();
            return;
        }

        /**
         * Este erro esta sendo gerado quando fecha ou troca de camera utilizando
         * o aplicativo nos dispositivos mobile.
         * A biblioteca de terceiro ZXING é controla a camera, foi atualizado
         * para a versão atual no momento (08/12/2022) e não está corrigido.
         * Aguardamos as proximas versões que contenha a correção.
         *
         * Este erro não está interferindo no comportamento do aplicativo, sendo
         * assim estamos ignorando.
         */
        const setOptionsFailedMessage = /setOptions failed/;
        if (setOptionsFailedMessage.test(error?.message)) {
            return;
        }

        /**
         * Este erro esta acontecendo com o dispositivo iPhone, quando fica inativo por algum tempo.
         * Não existe maneiras de restabelecer conexão om o Indexed Database, necessário recarregar
         * o aplicativo.
         *
         * Ps: Talvez seja possivel indentificar quando o aplicativo fique em segundo plano e pausar
         * as conexões até que ele volte a primeiro plano.
         *
         * User Agent:
         *   Mozilla/5.0 (iPhone; CPU iPhone OS 16_1_1 like Mac OS X)
         *   AppleWebKit/605.1.15 (KHTML, like Gecko)
         *   Version/16.1 Mobile/15E148 Safari/604.1
         */
        const indexedDatabaseConnectionLostedMessage = /Connection to Indexed Database server lost. Refresh the page to try again/;
        if (indexedDatabaseConnectionLostedMessage.test(error.message)) {
            window.location.reload();
            return;
        }


        /**
         * Falha a comunição do client/browser com o firebase através a internet.
         *
         * Ps: Não indica necessáriamente que não existe comunicação com a internet,
         * mas sim que a comunicação com o servidor do firebase (firestore/auth)
         * falhou.
         */
        const foo = /Failed to get document because the client is offline./;
        if (foo.test(error.message)) {
            const message = 'Falha ao se comunicar com a internet, verifique a internet, recarregue a pagina e tente novamente.'
            this.openErrorDialog(message);
            return;
        }


        /**
         * Check if it's an error from an HTTP response
         * Captura erro de uma requisição
         */
        if (!(error instanceof HttpErrorResponse)) {
            error = error.rejection ?? error; // get the error object
        }


        const message = `${error?.message ?? error}\n${error?.stack ?? ''}`;
        const status = error?.status;
        this.openErrorDialog(message, status);
        if (environment.production) {
            this.sendErrorToServer(error)
                .catch(err => console.error('Error on sendErrorToServer', err));
        }
    }

    private openErrorDialog(message: string, status?: number): void {
        this.zone.run(() => {
            this.errorDialogService.openDialog(message, status);
        });
    }


    private sendErrorToServer(error: any): Promise<void> {
        const root = this.getRootRoute(this.route.snapshot);
        const organizacao = this.getOrganizacaoId(root);
        const message = error?.message ?? error ?? undefined;
        const userAgent = window?.navigator?.userAgent ?? undefined;
        const stack = error?.stack ?? undefined;

        const registro = `Message: ${message} \n UserAgent: ${userAgent} \n Stack: ${stack}`;

        const attr: LogAttr = {
            evento: 'Global Error Handler',
            aplicacaoNome: environment.name,
            aplicacaoVersao: environment.version,
            level: 'error',
            organizacao: organizacao,
            message: message,
            registro: registro,
            hash: null,
        }
        const md5 = this.criptoService.md5(JSON.stringify(attr));
        const log = new Log({ ...attr, hash: md5 });
        return this.errorApiService.send(log);
    }

    private getOrganizacaoId(route: ActivatedRouteSnapshot) {
        const params = route.params;
        const organizacao = params.organizacao ?? params.organizacaoId ?? null;
        if (organizacao)
            return organizacao;

        return route.children.map(a => this.getOrganizacaoId(a)).first();
    };


    private getRootRoute(route: ActivatedRouteSnapshot) {
        if (route.parent)
            return this.getRootRoute(route.parent);
        return route;
    };


}
