import { Clipboard } from '@angular/cdk/clipboard';
import { Directive, EventEmitter, HostListener, Input, Optional, Output, ViewContainerRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';


@Directive({
    selector: '[clipboard]',
})
export class ClipboardDirective {

    @Input('copy') value: string;
    @Output('copied') copiedEvent: EventEmitter<string> = new EventEmitter();

    private tooltipOriginalMessage: string;

    constructor(
        viewContainer: ViewContainerRef,
        private clipboard: Clipboard,
        @Optional() private tooltip: MatTooltip,
    ) {
        if (tooltip) {
            this.tooltipOriginalMessage = this.tooltip.message;
        }
    }

    @HostListener('click', ['$event'])
    public onClick(event: Event): void {
        this.clipboard.copy(this.value || '');
        this.copiedEvent.emit(this.value);

        if (this.tooltip) {
            this.tooltip.message = 'Copiado!';
            this.tooltip.show();
            setTimeout(() => {
                this.tooltip.hide();
                this.tooltip.message = this.tooltipOriginalMessage;
            }, 2500);
        }
    }

}
