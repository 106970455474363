import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FLAGS } from 'src/app/app.constants';


@Pipe({ name: 'flag' })
export class FlagPipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) { }
    transform(value: string): string {
        return FLAGS[value]?.icon;
    }
}



@NgModule({
    declarations: [FlagPipe],
    imports: [CommonModule],
    exports: [FlagPipe],
    providers: [FlagPipe]
})
export class FlagPipeModule { }
