import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { LojaRepository } from '../domain/entities/loja.repository';
import { LojaCollection } from '../infra/firebase/loja/loja.collection';
import { LojaGuid } from '../infra/firebase/loja/loja.guid';


@Injectable({
    providedIn: 'root'
})
export class LojaGuard implements CanActivate, CanActivateChild {

    constructor(
        @Inject(LojaCollection)
        private loja: LojaRepository,
        private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.check(route, state);
    }
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.check(childRoute, state);
    }


    private async check(next: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Promise<boolean | UrlTree> {
        const id = next.params.organizacao || next.parent.params.organizacao;
        if (!id) {
            console.warn(`param organizacao not defined`)
            return this.router.createUrlTree(['not-found']);
        };

        const loja = await firstValueFrom(this.loja.get(new LojaGuid(id)));
        if (!loja) {
            console.warn(`loja ${id} not found`)
            return this.router.createUrlTree(['not-found']);
        }

        if (!loja.enabled) {
            console.warn(`loja ${id} is not enabled`)
            return this.router.createUrlTree(['not-found']);
        }

        return true;
    }
}
