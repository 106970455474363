export type GuidKeys = { [id: string]: string | number | boolean };

export interface GuidInterface {
    get id(): string;
    get keys(): GuidKeys;
}


export abstract class Guid implements GuidInterface {

    constructor(
        private readonly _id: string,
        private readonly _keys: GuidKeys,
    ) { }


    get id(): string {
        return this._id;
    }
    get keys(): GuidKeys {
        return Object.assign({}, this._keys);
    }

    abstract toString(): string;
}

