import { Guid } from "src/app/domain/util/guid";



/**
 * @keys É utilizado para mapear o caminho absoluto do documento
 * ex: { parent: parentId, child: childId}
 * path: /parent/parentId/child/childId
 */
export class FirebaseGuid extends Guid {
    toString(): string {
        return Object.keys(this.keys)
            .map(k => `${k}/${this.keys[k]}`)
            .join('/');
    }
}

