import { Inject, Injectable, InjectionToken } from '@angular/core';
import { interval, map, Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageConfig, LocalStorageDataType } from './local-storage.config';

/**
 * This is not a real service, but it looks like it from the outside.
 * It's just an InjectionTToken used to import the config object, provided from the outside
 */
export const LocalStorageConfigService = new InjectionToken<LocalStorageConfig>('LocalStorageConfig');


@Injectable({ providedIn: 'root' })
export class LocalStorageService {

    constructor(
        @Inject(LocalStorageConfigService)
        private _config: LocalStorageConfig,
    ) {
        this.initialize();
        interval(1000)
            .pipe(map(() => {
                const db = this.connection();
                const info = db.getItem('database-info')
                if (!info) {
                    throw new Error('database-info/not-found');
                }
            }))
            .subscribe();
    }

    private initialize() {
        const db = this.connection();
        if (!db.getItem('database-info')) {
            const info = this.defaultDataBaseInfo();
            db.clear();
            db.setItem('database-info', JSON.stringify(info));
        }
    }

    private defaultDataBaseInfo() {
        return {
            appName: environment.name,
            appVersion: environment.version,
            createdAt: new Date().toISOString(),
        };
    }


    private connection() {
        const db = this._config.storage;
        switch (db) {
            case 'local': return localStorage;
            case 'session': return sessionStorage;
        }
    }

    public getAll<T extends LocalStorageDataType>(collection: string): Observable<T[]> {
        const db = this.connection();
        const result = new Observable<T[]>(sub => {
            try {
                const data = Array.from({ length: db.length })
                    .map((a, i) => db.key(i))
                    .filter(i => i.split(":")[0] === collection)
                    .map(key => {
                        const raw = db.getItem(key);
                        return this.tryJsonParse(raw);
                    });

                sub.next(data);
            } catch (err) {
                sub.error(err);
            } finally {
                sub.complete();
            }
        });
        return result;
    }


    getByID<T extends LocalStorageDataType>(collection: string, key: string): Observable<T> {
        const db = this.connection();
        const result = new Observable<T>(sub => {
            try {
                const data = Array.from({ length: db.length })
                    .map((a, i) => db.key(i))
                    .filter(i => {
                        const split = i.split(":");
                        return split[0] === collection && split[1] === key
                    })
                    .map(key => db.getItem(key))
                    .map(raw => this.tryJsonParse(raw))
                    ;

                sub.next(data.first());
            } catch (err) {
                sub.error(err);
            } finally {
                sub.complete();
            }
        });
        return result;
    }

    update<T extends LocalStorageDataType>(collection: string, data: T): Observable<T> {
        const db = this.connection();
        const result = new Observable<T>(sub => {

            try {
                db.setItem(`${collection}:${data.id}`, JSON.stringify(data))
                sub.next(data);
            } catch (err) {
                sub.error(err);
            } finally {
                sub.complete();
            }
        });
        return result;
    }

    delete<T extends LocalStorageDataType>(collection: string, key: string): Observable<void> {
        const db = this.connection();
        const result = new Observable<void>(sub => {
            try {
                if (db.getItem(`${collection}:${key}`))
                    db.removeItem(`${collection}:${key}`);
                sub.next();
            } catch (err) {
                sub.error(err);
            } finally {
                sub.complete();
            }
        });
        return result;
    }

    private tryJsonParse(raw: string) {
        try {
            return raw ? JSON.parse(raw) : null;
        } catch (err) {
            return null;
        }
    }
}
