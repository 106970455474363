import firebase from 'firebase/compat/app';
import { NgxFirebaseDoc } from '@azoup/ngx-firebase';
import { OrganizacaoSchema } from './organizacao.schema';

export class OrganizacaoDoc extends NgxFirebaseDoc<OrganizacaoSchema> {

    constructor(snap?: firebase.firestore.QueryDocumentSnapshot | firebase.firestore.DocumentSnapshot | firebase.firestore.DocumentChange) {
        super(snap);
    }

}
