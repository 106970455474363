import { EventEmitter, Injectable } from '@angular/core';
import { FirebaseError } from 'firebase/app';
import { Auth, createUserWithEmailAndPassword, deleteUser, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateProfile, User } from 'firebase/auth';
import { debounceTime, first, Observable, takeUntil, takeWhile } from 'rxjs';
import { FirebaseService } from '../firebase.service';

export type FirebaseAuthErrorKeys = keyof typeof FirebaseAuthErrorMessage;
export enum FirebaseAuthErrorMessage {

    /**
     * Lançado se já existir uma conta com o endereço de e-mail declarado pela credencial.
     * Resolva isso chamando firebase.auth.Auth.fetchSignInMethodsForEmail com error.email e
     * solicitando que o usuário faça login usando um dos provedores retornados.
     * Depois que o usuário fizer login, a credencial original recuperada do error.credential
     * poderá ser vinculada ao usuário com firebase.User.linkWithCredential para impedir que o
     * usuário faça login novamente no provedor original por meio de pop - up ou redirecionamento.
     * Se você estiver usando redirecionamentos para fazer login, salve a credencial no armazenamento da sessão e,
     * em seguida, recupere no redirecionamento e preencha novamente a credencial usando, por exemplo,
     * firebase.auth.GoogleAuthProvider.credential dependendo do ID do provedor de credenciais e preencha o link.
     * */
    'auth/account-exists-with-different-credential' = 'Email já cadastrado a provedor diferente.',


    /**
     * Lançado se a conta correspondente à credencial já existir entre seus usuários ou
     *  já estiver vinculada a um usuário do Firebase. Por exemplo, esse erro pode ser gerado
     *  se você estiver atualizando um usuário anônimo para um usuário do Google vinculando uma
     *  credencial do Google a ele e a credencial do Google usada já estiver associada a um
     *  usuário existente do Firebase do Google. Os campos error.email, error.phoneNumber e
     *  error.credential (firebase.auth.AuthCredential) podem ser fornecidos, dependendo do tipo
     *  de credencial. Você pode se recuperar desse erro fazendo login com error.credential
     *  diretamente por meio de firebase.auth.Auth.signInWithCredential.
     * */
    'auth/credential-already-in-use' = 'Credencial já em uso.',

    /**
     * Lançado se o e-mail correspondente à credencial já existir entre seus usuários.
     *  Quando acionado ao vincular uma credencial a um usuário existente, os campos
     *  error.email e error.credential (firebase.auth.AuthCredential) também são fornecidos.
     *  Você precisa vincular a credencial ao usuário existente com esse e-mail se desejar
     *  continuar fazendo login com essa credencial. Para fazer isso, chame
     *  firebase.auth.Auth.fetchSignInMethodsForEmail, faça login em error.email por meio
     *  de um dos provedores retornados e, em seguida, firebase.User.linkWithCredential a
     *  credencial original para esse usuário recém-conectado.
     * */
    'auth/email-already-in-use' = 'Email já em uso.',


    /**
     * Lançado se o usuário correspondente não for encontrado.
     */
    'auth/user-not-found' = 'Usuário não encontrado',

    /**
     * Lançado se o usuário utilizou uma senha que não corresponde com a credencial cadastrada.
     */
    'auth/wrong-password' = 'Senha não é valida.',

    /**
     * Lançado se a instância do FirebaseApp foi excluída.
     * */
    'auth/app-deleted' = 'Aplicação não existe.',

    /**
     *  Lançado se o aplicativo identificado pelo domínio em que está hospedado não
     *  estiver autorizado a usar o Firebase Authentication com a chave de API fornecida.
     *  Revise sua configuração de chave no console de API do Google.
     * */
    'auth/app-not-authorized' = 'Revise sua configuração de chave.',

    /**
     * Lançado se um método for chamado com argumentos incorretos.
     *  */
    'auth/argument-error' = 'Argumentos incorretos.',

    /**
     * Lançado se a chave de API fornecida for inválida. Verifique se você copiou
     * corretamente do Firebase Console.
     * */
    'auth/invalid-api-key' = 'Chave inválida.',

    /**
     * Lançado se a credencial do usuário não for mais válida. O usuário deve
     *  fazer login novamente.
     * */
    'auth/invalid-user-token' = 'Credencial do usuário inválida. Fazer login novamente.',

    /**
     * Lançado se o ID do locatário fornecido for inválido.
     * */
    'auth/invalid-tenant-id' = 'ID do locatário inválido.',

    /**
     * Lançado se ocorrer um erro de rede (como tempo limite, conexão interrompida
     * ou host inacessível).
     * */
    'auth/network-request-failed' = 'Erro de rede (como tempo limite, conexão interrompida ou host inacessível).',

    /**
     * Lançado se você não ativou o provedor no Firebase Console. Acesse o Firebase
     *  Console do seu projeto, na seção Auth e na guia Sign in Method e configure o provedor.
     * */
    'auth/operation-not-allowed' = 'Provedor inativo.',

    /**
     * Lançado se o horário da última entrada do usuário não atender ao limite de
     * segurança. Use firebase.User.reauthenticateWithCredential para resolver. Isso não se aplica se o usuário for anônimo.
     * */
    'auth/requires-recent-login' = 'Horário da última entrada do usuário não atende ao limite de segurança.',

    /**
     * Lançado se as solicitações forem bloqueadas de um dispositivo devido a atividade
     *  incomum. Tentar novamente após algum atraso desbloquearia.
     * */
    'auth/too-many-requests' = 'Solicitações bloqueadas. Tentar novamente mais tarde.',

    /**
     * Lançado se o domínio do aplicativo não estiver autorizado para operações
     * OAuth para seu projeto do Firebase. Edite a lista de domínios autorizados
     * no console do Firebase.
     * */
    'auth/unauthorized-domain' = 'Dominio não autorizado.',

    /**
     * Lançado se a conta de usuário foi desabilitada por um administrador. As contas
     *  podem ser ativadas ou desativadas no Firebase Console, na seção Auth e na
     * subseção Users.
     * */
    'auth/user-disabled' = 'Usuário foi desabilitado por um administrador.',

    /**
     * Lançado se a credencial do usuário expirou. Isso também pode ser gerado se um
     * usuário tiver sido excluído. Solicitar que o usuário faça login novamente deve
     * resolver isso para ambos os casos.
     * */
    'auth/user-token-expired' = 'Credencial do usuário expirou. Faça login novamente.',

    /**
     * Lançado se o navegador não suportar armazenamento na web ou se o usuário os
     * desabilitar.
     * */
    'auth/web-storage-unsupported' = 'Não suporta armazenamento.',
}

@Injectable({
    providedIn: 'root'
})
export class FirebaseAuthService {

    private auth: Auth;
    private user: User | null = null;
    private emiter: EventEmitter<User | null> = new EventEmitter();
    private initialized: boolean = false;

    constructor(firebase: FirebaseService) {
        this.auth = firebase.auth();
        this.user = this.auth.currentUser;

        this.auth.onAuthStateChanged(user => this.authenticate(user));
        this.auth.onIdTokenChanged(user => this.authenticate(user));
    }

    private authenticate(user: User | null) {
        this.user = user;
        this.emiter.next(user);
        this.initialized = true;
    }

    public init(): Promise<boolean> {
        if (this.initialized)
            return Promise.resolve(true);

        return new Promise<boolean>(resolve => setTimeout(() => resolve(this.init()), 250));
    }

    public observe(): Observable<User | null> {
        return this.emiter.asObservable().pipe(debounceTime(25));
    }

    public getUser(): User | null {
        return this.user;
    }

    async delete(user: User): Promise<void> {
        await deleteUser(user);
    }

    async signup(email: string, password: string): Promise<User> {
        createUserWithEmailAndPassword(this.auth, email, password)
            .catch(this.handleError);

        return new Promise<User>((resolve, reject) => {
            this.observe()
                .pipe(first())
                .subscribe(user => {
                    user ? resolve(user) : reject('No user logged')
                });
        })

    }

    async sendEmailVerification(user: User): Promise<void> {
        await sendEmailVerification(user)
            .catch(this.handleError);;
    }


    async sendPasswordResetEmail(email: string): Promise<void> {
        await sendPasswordResetEmail(this.auth, email);
    }

    async signin(email: string, password: string) {
        const cred = await signInWithEmailAndPassword(this.auth, email, password)
            .catch(this.handleError);

        return new Promise<User>((resolve, reject) => {
            this.observe()
                .pipe(first())
                .subscribe(user => {
                    user ? resolve(user) : reject('No user logged')
                });
        })
    }

    async signout(): Promise<void> {
        signOut(this.auth)
            .catch(this.handleError);

        return new Promise<void>((resolve, reject) => {
            this.observe()
                .pipe(first())
                .subscribe(user => {
                    !user ? resolve() : reject('No user logged')
                });
        })
    }

    async updateProfile(user: User, opt: { displayName?: string, photoURL?: string } = {}) {
        if (user === null) {
            throw new Error('no user');
        }
        if (!opt.displayName && !opt.photoURL)
            return;

        return updateProfile(user, { ...opt })
            .catch(this.handleError);
    }


    private handleError(error: FirebaseError) {
        return Promise.reject({ code: error.code, message: FirebaseAuthErrorMessage[error.code as FirebaseAuthErrorKeys] });
    }

}
