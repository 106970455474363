import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroupUI } from '@azoup/ngx-ui';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalFormComponent } from './modal-form/modal-form.component';
import { ModalListComponent } from './modal-list/modal-list.component';
import { ModalLoadingComponent } from './modal-loading/modal-loading.component';
import { ModalMessageComponent } from './modal-message/modal-message.component';
import { ModalMessage, ModalMessageOpts } from './modal-message/modal-message.interface';
import { ModalProgressBarComponent } from './modal-progress-bar/modal-progress-bar.component';
import { ModalProgressBar, ModalProgressBarOpts } from './modal-progress-bar/modal-progress-bar.interface';
import { ModalExpansionListComponent } from './modal-expansion-list/modal-expansion-list.component';

export class ModalButton  {
    label: string;
    validation?: (opts: ModalFormOpts) => boolean | Promise<boolean>;
}

export interface ModalConfirmOpts {
    title: string;
    message?: string;
    messages?: string[];
    confirm: string | ModalButton;
    cancel: string | ModalButton;
}


export type ModalConfirmResponseType = 'confirm' | 'cancel';
export type ModalResponseType = ModalConfirmResponseType | 'delete';

export interface ModalFormOpts extends ModalConfirmOpts {
    form: FormGroupUI;
    delete?: string | ModalButton;
}
export interface ModalFormResult {
    response: ModalResponseType;
    form: FormGroupUI;
}

export interface ModalFormOpts extends ModalConfirmOpts {
    form: FormGroupUI;
    delete?: string  | ModalButton;
}
export interface ModalFormResult {
    response: ModalResponseType;
    form: FormGroupUI;
}

export interface ModalListItem {
    icon?: string;
    label: string;
    description?: string;
    value: any;
    selected?: boolean;
    onClick?: (item: ModalListItem) => any;
}


export type ModalListResponseType = 'confirm' | 'cancel';
export interface ModalListResult {
    response: ModalListResponseType;
    selected: ModalListItem[];
    list: ModalListItem[];
}
export interface ModalListOpts {
    title: string;
    message?: string;
    list: ModalListItem[];
    confirm: {
        label: string;
        validation?: (opts: ModalListOpts) => boolean;
    };
    cancel?: {
        label: string;
        validation?: (opts: ModalListOpts) => boolean;
    };
}


export interface ModaExpansionListItem {
    label: string;
    description?: string;
    content: string;
}


export interface ModalExpansionOpts {
    title: string;
    message?: string;
    list: ModaExpansionListItem[];
    confirm: {
        label: string;
        validation?: (opts: ModalListOpts) => boolean;
    };
    cancel?: {
        label: string;
        validation?: (opts: ModalListOpts) => boolean;
    };
}

export interface ModalLoadingOpts {
    title?: string;
    message?: string;
    mode?: 'determinate' | 'indeterminate';
    percent?: number;
    percentStart?: number;
    percentEnd?: number;
    estimative?: number;
}

@Injectable({ providedIn: 'root' })
export class ModalService {


    constructor(private dialog: MatDialog) { }


    public openConfirm(opt: ModalConfirmOpts): Promise<ModalConfirmResponseType> {
        const config = new MatDialogConfig();
        config.data = opt;
        return new Promise<ModalConfirmResponseType>(resolve => {
            const modal = this.dialog.open(ModalConfirmComponent, config);
            modal.afterClosed().subscribe(res => resolve(res));
        });
    }


    public openForm(opt: ModalFormOpts): Promise<ModalFormResult> {
        const config = new MatDialogConfig();
        config.data = opt;
        return new Promise<ModalFormResult>(resolve => {
            const modal = this.dialog.open<ModalFormComponent, ModalFormOpts, ModalFormResult>(ModalFormComponent, config);
            modal.afterClosed().subscribe(res => resolve(res));
        });
    }



    public openExpansionList(opt: ModalExpansionOpts): Promise<void> {
        const config = new MatDialogConfig();
        config.minWidth = '320px';
        config.data = opt;
        return new Promise<void>(resolve => {
            const modal = this.dialog.open(ModalExpansionListComponent, config);
            modal.afterClosed().subscribe(res => resolve(res));
        });
    }

    public openList(opt: ModalListOpts): Promise<ModalListResult> {
        const config = new MatDialogConfig();
        config.minWidth = '320px';
        config.data = opt;
        return new Promise<ModalListResult>(resolve => {
            const modal = this.dialog.open(ModalListComponent, config);
            modal.afterClosed().subscribe(res => resolve(res));
        });
    }

    public openLoading(opt?: ModalLoadingOpts) {
        const config = new MatDialogConfig();
        config.minWidth = '320px';
        config.minHeight = '140px';
        config.data = opt;
        config.disableClose = true;
        const modal = this.dialog.open(ModalLoadingComponent, config);
        const r = {
            close: () => modal.close(),
            update: (opt: ModalLoadingOpts, arg?: { force?: boolean }) => modal.componentInstance.update(opt, arg),
            progress: async <T>(tasks: (() => T)[], arg?: { percentMin?: number, percentMax?: number }) => {
                let executedTask = 0;
                const taskResults = [];
                const timeStart = Date.now();
                for (const t of tasks) {
                    taskResults.add(await t());
                    const duration = Date.now() - timeStart;
                    const percent = (++executedTask * (arg?.percentMax ?? 100)) / tasks.length;
                    const estimative = (duration * (arg?.percentMax ?? 100) / percent) - duration;
                    await modal.componentInstance.update({ percent, estimative });
                }
                return taskResults;
            }
        }
        return new Promise<{
            close: () => void,
            update: (data: ModalLoadingOpts, arg?: { force?: boolean } | undefined) => void,
            progress: <T>(tasks: (() => T)[], arg?: { percentMin?: number, percentMax?: number }) => Promise<T[]>
        }>(resolve => {
            modal.afterOpened().subscribe(() => resolve(r))
        });
    }

    public openProgressBar(opt?: ModalProgressBarOpts) {
        const config = new MatDialogConfig();
        config.width = '100vw';
        config.maxWidth = '300px';

        config.data = opt;
        config.disableClose = true;
        config.hasBackdrop = true;

        const dlg = this.dialog.open(ModalProgressBarComponent, config);
        const comp = dlg.componentInstance as ModalProgressBarComponent;
        return new Promise<ModalProgressBar>(resolve => dlg.afterOpened().subscribe(() => resolve(comp)));
    }

    public openMessage(opt: ModalMessageOpts): Promise<ModalMessage> {
        const config = new MatDialogConfig();
        config.minWidth = '320px';

        config.data = opt;
        config.disableClose = opt.disableClose ?? false;
        config.hasBackdrop = true;

        const dlg = this.dialog.open(ModalMessageComponent, config);
        const comp = dlg.componentInstance as ModalMessageComponent;
        return new Promise<ModalMessage>(resolve => dlg.afterOpened().subscribe(() => resolve(comp)));
    }
}
