import firebase from 'firebase/compat/app';

import { NgxFirebaseDoc, NgxFirebaseService } from '@azoup/ngx-firebase';
import { OrganizacaoUsuarioSchema } from './organizacao-usuario.schema';

export class OrganizacaoUsuarioDoc extends NgxFirebaseDoc<OrganizacaoUsuarioSchema> {

    constructor(snap?: firebase.firestore.QueryDocumentSnapshot) {
        super(snap);
    }


}
