import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../modules/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private auth: AuthService,
        private router: Router,
    ) { }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return await this.isLogged(state);
    }
    async canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return await this.isLogged(state);
    }

    async isLogged(state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (this.auth.isLogged()) { return true; }
        return this.router.createUrlTree(['login'], { queryParams: { redirectUrl: state.url } });
    }

}
