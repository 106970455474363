<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
    <p *ngIf="data.message">{{data.message}}</p>


    <div
        class="example-action-buttons"
        [hidden]="true"
    >
        <button
            mat-button
            (click)="accordion.openAll()"
        >Abrir Todos</button>
        <button
            mat-button
            (click)="accordion.closeAll()"
        >Fechar Todos</button>
    </div>
    <mat-accordion class="example-headers-align">
        <mat-expansion-panel #panel
            *ngFor="let i of data.list"
            hideToggle

        >
            <mat-expansion-panel-header>
                <mat-panel-title>{{i.label}}</mat-panel-title>
                <mat-panel-description *ngIf="i.description">{{i.description}}
                    <mat-icon *ngIf="i.icon">{{i.icon}}</mat-icon>
                </mat-panel-description>
                <button
                    mat-stroked-button
                    color="warn"
                    *ngIf="!panel.expanded"
                >
                    <span>Mais </span>
                    <mat-icon class="ml-1">expand_more</mat-icon>
                </button>
                <button
                    mat-stroked-button
                    color="warn"
                    *ngIf="panel.expanded"
                >
                    <span>Menos </span>
                    <mat-icon class="ml-1">expand_less</mat-icon>
                </button>
            </mat-expansion-panel-header>

            <div
                style="padding-left: 2rem;max-height: 256px;"
                class="scrollbar"
            >
                <code>{{i.content}}</code>
            </div>
        </mat-expansion-panel>

    </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions>
    <button
        *ngIf='data.cancel'
        mat-button
        (click)='onClickCancel()'
    >{{data.cancel.label}}</button>
    <button
        mat-raised-button
        (click)='onClickConfirm()'
        color='orange'
    >{{data.confirm.label}}</button>
</mat-dialog-actions>
