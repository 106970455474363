import { Injectable } from '@angular/core';
import { Log } from 'src/app/domain/entities/log/log.model';
import { LogRepository } from 'src/app/domain/entities/log/log.repository';

@Injectable({ providedIn: 'root' })
export class ErrorApiService {

    constructor(
        private api: LogRepository
    ) { }


    async send(log: Log): Promise<void> {
        return this.api.save(log);
    }
}
