import { Injectable } from '@angular/core';
import { OrganizacaoCollection } from 'src/app/_firebase/organizacao/organizacao.collection';
import { OrganizacaoDoc } from 'src/app/_firebase/organizacao/organizacao.doc';
import { OrganizacaoModel } from 'src/app/_models/organizacao.model';

@Injectable({ providedIn: 'root' })
export class OrganizacaoService {


    constructor(
        private organizacaoCollection: OrganizacaoCollection,
    ) { }

    async getById(id: string): Promise<OrganizacaoModel> {
        const doc = await this.organizacaoCollection.get(id);
        if (!doc) { return null; }
        return this.buildOrganizacaoModelFromDoc(doc);

    }


    private buildOrganizacaoModelFromDoc(doc: OrganizacaoDoc): OrganizacaoModel {
        return new OrganizacaoModel(doc.id, doc.data);
    }

    async salvar(model: OrganizacaoModel): Promise<void> {
        if (model.id) {

            return this.organizacaoCollection.doc(model.id, { ...model }).then(() => { });
        }
    }

    async setNotification(id: string, on: boolean): Promise<void> {
        return this.organizacaoCollection.ref(id).set({ notification: on }, { merge: true });
    }

    async setLojaEntregaValorMinimo(id: string, entregaValorMinimo: number): Promise<void> {
        return this.organizacaoCollection.ref(id).set({ loja: { entregaValorMinimo } }, { merge: true });
    }

    async getAll(): Promise<OrganizacaoModel[]> {
        const docs = await this.organizacaoCollection.getAll();
        return docs.map(d => this.buildOrganizacaoModelFromDoc(d));
    }


}
