import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalFormOpts, ModalLoadingOpts } from '../modal.service';

@Component({
    selector: 'app-modal-loading',
    templateUrl: './modal-loading.component.html',
    styleUrls: ['./modal-loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalLoadingComponent implements OnInit, OnDestroy {

    title: string;
    message: string;
    mode: 'determinate' | 'indeterminate';
    percent: number;
    estimative: number;

    private lastUpdateTime: number = 0;

    constructor(
        private cdr: ChangeDetectorRef,
        public dialogRef: MatDialogRef<ModalLoadingComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ModalLoadingOpts,
    ) {

    }

    async ngOnInit(): Promise<void> {
        this.cdr.detach();
        await this.update(this.data);
    }

    ngOnDestroy(): void {
    }

    private timeout;


    public async update(data: ModalLoadingOpts, opt: { force?: boolean } = {}) {
        if (data.estimative) {
            data.estimative = Math.ceil(data.estimative);
        }
        if (data.percent) {
            data.percent = Math.ceil(data.percent);
        }

        // 4 frames por segundo
        const diff = (this.lastUpdateTime + 250) - Date.now();
        if (this.lastUpdateTime <= -1 || diff > 0) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = undefined;
            }
            if (opt.force) {
                return this.updateView(data);
            }

            this.timeout = setTimeout(async () => this.update(data), diff);
            return Promise.resolve();
        }

        return this.updateView(data);
    }


    private updateView(data: ModalLoadingOpts) {
        this.lastUpdateTime = -1;
        this.message = data?.message || this.message;
        this.title = data?.title || this.title;
        this.mode = data?.mode || this.mode;
        this.percent = Math.ceil(data?.percent ?? this.percent ?? 0);
        this.estimative = Math.ceil((data?.estimative ?? this.estimative ?? 0) / 1000);
        this.cdr.detectChanges()
        this.lastUpdateTime = Date.now();
        return new Promise<void>(resolve => setTimeout(() => resolve()));
    }




}
