import { DAY_OF_WEEK } from '../app.constants';
import { DayOfWeek, OrganizacaoSchema } from 'src/app/_firebase/organizacao/organizacao.schema';

export type TLeitura = 'todos' | 'cestou-qrcode' | 'qrcode';
export type TEntrada = 'todos' | 'leitor' | 'texto';
export type IHorario = { diaSemana: DayOfWeek, inicio: string, termino: string };

export type TLojaValues = {
    enabled: boolean;
    utilizarPedido: boolean;
    utilizarHorarioDeFuncionamento: boolean;
    entregaValorMinimo: number;
    horarios: IHorario[];
};


export type TCardapioValues = {
    enabled: boolean;
    utilizarPedido: boolean;
    utilizarFicha: boolean;
    utilizarMesa: boolean;
    utilizarHorarioDeFuncionamento: boolean;
    gerarFichaAutomaticamente: boolean;
    horarios: IHorario[];
    obrigatorioInformarFicha: boolean;
    obrigatorioInformarMesa: boolean;
    tipoEntradaFicha: TEntrada;
    tipoEntradaMesa: TEntrada;
    tipoLeituraFicha: TLeitura;
    tipoLeituraMesa: TLeitura;
};

export const DEFAULT_CARDAPIO_VALUES: TCardapioValues = {
    enabled: false,
    utilizarPedido: false,
    utilizarFicha: false,
    utilizarMesa: false,
    utilizarHorarioDeFuncionamento: false,
    gerarFichaAutomaticamente: true,
    horarios: [],
    obrigatorioInformarFicha: false,
    obrigatorioInformarMesa: false,
    tipoEntradaFicha: 'todos',
    tipoEntradaMesa: 'todos',
    tipoLeituraFicha: 'todos',
    tipoLeituraMesa: 'todos',
};

export const DEFAULT_LOJA_VALUES: TLojaValues = {
    enabled: false,
    utilizarPedido: true,
    utilizarHorarioDeFuncionamento: false,
    entregaValorMinimo: 0,
    horarios: [],
};

export class OrganizacaoModel {
    readonly id: string;

    nome: string = undefined;
    foto: string = undefined;
    entrega: {
        observacoes: string[];
        tempo: string;
        valor: number;
    } = undefined;
    retirada: {
        observacoes: string[];
        tempo: string;
        valor: number;
    } = undefined;
    coordenadas: {
        longitude: string,
        latitude: string
    } = undefined;
    endereco: {
        cep: string;
        logradouro: string;
        numero: string;
        bairro: string;
        municipio: string;
        uf: string;
    } = undefined;
    facebook: string = undefined;
    instagram: string = undefined;
    whatsapp: string = undefined;
    cardapio: TCardapioValues = undefined;
    loja: TLojaValues = undefined;
    notification: boolean = undefined;

    constructor(id: string, raw?: OrganizacaoSchema) {
        Object.keys(this).forEach(k => this[k] = raw[k]);
        this.id = id;
        this.cardapio = this.cardapio || DEFAULT_CARDAPIO_VALUES;
        Object.keys(DEFAULT_CARDAPIO_VALUES)
            .map(k => this.cardapio[k] = this.cardapio[k] === undefined ? DEFAULT_CARDAPIO_VALUES[k] : this.cardapio[k]);

        this.loja = this.loja || DEFAULT_LOJA_VALUES;
        Object.keys(DEFAULT_LOJA_VALUES)
            .map(k => this.loja[k] = this.loja[k] === undefined ? DEFAULT_LOJA_VALUES[k] : this.loja[k]);

    }

    public lojaIsOpened(date: Date): boolean {
        if (!this.loja.utilizarHorarioDeFuncionamento) {
            return true;
        }

        const horarios = this.getHorarios('loja');
        const opened = this.hasHorarioFuncionamento(horarios, date);
        return opened;
    }

    public cardapioIsOpened(date: Date): boolean {
        if (!this.cardapio.utilizarHorarioDeFuncionamento) {
            return true;
        }
        const horarios = this.getHorarios('cardapio');
        const opened = this.hasHorarioFuncionamento(horarios, date);
        return opened;
    }

    private hasHorarioFuncionamento(horarios: IHorario[], date: Date): boolean {
        const d = date.getDay();
        const h = date.getHours();
        const m = date.getMinutes();
        const t1 = h * 60 + m;

        return horarios
            .filter(a => a.diaSemana === DAY_OF_WEEK[d].type)
            .some(a => t1 >= this.t2n(a.inicio) && t1 <= this.t2n(a.termino));
    }


    private getHorarios(from: 'cardapio' | 'loja'): IHorario[] {
        switch (from) {
            case 'cardapio': return this.cardapio.horarios;
            case 'loja': return this.loja.horarios;
            default: throw new Error('Invalid param');
        }
    }


    private t2n(time: string): number {
        if (!time) { return 0; }
        const s = time.split(':').map(a => +a);
        return s[0] * 60 + s[1];
    }


}
