<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content *ngIf='data.message || data.messages'>
    <span *ngIf='data.message'>{{data.message}}</span>
    <ng-container *ngIf='data.messages'>
        <p *ngFor='let m of data.messages'>{{m}}</p>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions>
    <button *ngIf='data.cancel' mat-button [mat-dialog-close]='"cancel"'>{{view.cancel}}</button>
    <button mat-button [mat-dialog-close]="'confirm'">{{view.confirm}}</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
</mat-dialog-actions>
