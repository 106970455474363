import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalButton, ModalConfirmOpts } from '../modal.service';
import { Button } from 'protractor';

@Component({
    selector: 'app-modal-confirm',
    templateUrl: './modal-confirm.component.html',
    styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

    view = {
        cancel: "Cancelar",
        confirm: "Confirmar",
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: ModalConfirmOpts) { }

    ngOnInit(): void {
        this.data.cancel = this.data.cancel instanceof ModalButton
            ? this.data.cancel.label
            : this.data.cancel
            ;

        this.data.confirm = this.data.confirm instanceof ModalButton
            ? this.data.confirm.label
            : this.data.confirm
            ;
    }

}
