import { Injectable } from "@angular/core";
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { Cardapio } from "src/app/domain/entities/cardapio.model";
import { Guid } from "src/app/domain/util/guid";
import { CardapioGuid } from "./cardapio.guid";
import { CardapioSchema } from "./cardapio.schema";


@Injectable({ providedIn: 'root' })
export class CardapioFactory implements FirestoreDataConverter<Cardapio> {
    toFirestore(model: Cardapio): CardapioSchema {
        return ({
            id: model.id,
            organizacaoId: model.organizacaoId,
            accessPoint: model.accessPoint,
            enabled: model.enabled,
            obrigatorioInformarFicha: model.obrigatorioInformarFicha,
            obrigatorioInformarMesa: model.obrigatorioInformarMesa,
            tipoEntradaFicha: model.tipoEntradaFicha,
            tipoEntradaMesa: model.tipoEntradaMesa,
            tipoLeituraFicha: model.tipoLeituraFicha,
            tipoLeituraMesa: model.tipoLeituraMesa,
            utilizarFicha: model.utilizarFicha,
            utilizarMesa: model.utilizarMesa,
            gerarFichaAutomaticamente: model.gerarFichaAutomaticamente,
            horarios: model.horarios,
            utilizarHorarioDeFuncionamento: model.utilizarHorarioDeFuncionamento,
            utilizarPedido: model.utilizarPedido,
            utilizarEstoque: model.utilizarEstoque,
            enviarPedidoPeloWhats: model.enviarPedidoPeloWhats ??true,
        });
    }
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options?: SnapshotOptions | undefined): Cardapio {
        const {
            accessPoint,
            enabled,
            organizacaoId,
            obrigatorioInformarFicha,
            obrigatorioInformarMesa,
            tipoEntradaFicha,
            tipoEntradaMesa,
            tipoLeituraFicha,
            tipoLeituraMesa,
            utilizarFicha,
            utilizarMesa,
            gerarFichaAutomaticamente,
            horarios,
            utilizarHorarioDeFuncionamento,
            utilizarPedido,
            utilizarEstoque,
            enviarPedidoPeloWhats,
        } = snapshot.data(options);
        const guid: Guid = new CardapioGuid(snapshot.id);
        return new Cardapio({
            accessPoint,
            enabled,
            organizacaoId,
            obrigatorioInformarFicha,
            obrigatorioInformarMesa,
            tipoEntradaFicha,
            tipoEntradaMesa,
            tipoLeituraFicha,
            tipoLeituraMesa,
            utilizarFicha,
            utilizarMesa,
            gerarFichaAutomaticamente,
            horarios,
            utilizarHorarioDeFuncionamento,
            utilizarPedido,
            utilizarEstoque,
            enviarPedidoPeloWhats,
        }, guid);
    }
}
