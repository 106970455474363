import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { CardapioCollection } from '../infra/firebase/cardapio/cardapio.collection';
import { CardapioGuid } from '../infra/firebase/cardapio/cardapio.guid';
import { CardapioRepository } from '../modules/organizacao/repositories/cardapio.repository';

@Injectable({ providedIn: 'root' })
export class CardapioGuard implements CanActivate, CanActivateChild {

    constructor(
        @Inject(CardapioCollection)
        private cardapio: CardapioRepository,
        private router: Router,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.check(route, state);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.check(childRoute, state);
    }


    private async check(next: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Promise<boolean | UrlTree> {

        const id = next.params.organizacao || next.parent.params.organizacao;
        if (!id) {
            console.warn(`param organizacao not defined`)
            return this.router.createUrlTree(['not-found']);
        };

        const cardapio = await firstValueFrom(this.cardapio.get(new CardapioGuid(id)));
        if (!cardapio) {
            console.warn(`cardapio ${id} not found`)
            return this.router.createUrlTree(['not-found']);
        }

        if (!cardapio.enabled) {
            console.warn(`cardapio ${id} is not enabled`)
            return this.router.createUrlTree(['not-found']);
        }

        return true;
    }
}
