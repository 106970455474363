import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Debounce } from 'lodash-decorators';
import { APP_CLICK_DEBOUNCE_TIMEOUT } from 'src/app/app.constants';
import { ModalButton, ModalFormOpts, ModalFormResult } from '../modal.service';
import { throwDialogContentAlreadyAttachedError } from '@angular/cdk/dialog';

@Component({
    selector: 'app-modal-form',
    templateUrl: './modal-form.component.html',
    styleUrls: ['./modal-form.component.scss']
})
export class ModalFormComponent implements OnInit {


    view = {
        confirm: "Confirmar",
        cancel: "Cancelar",
        delete: "Deletar"
    }

    constructor(
        public dialogRef: MatDialogRef<ModalFormComponent, ModalFormResult>,
        @Inject(MAT_DIALOG_DATA) public data: ModalFormOpts
    ) { }

    ngOnInit(): void {
        this.view.confirm = this.data.confirm ? this.data.confirm['label'] ?? this.data.confirm ?? this.view.confirm : this.data.confirm;
        this.view.cancel = this.data.cancel ? this.data.cancel['label'] ?? this.data.cancel ?? this.view.cancel : this.view.cancel;
        this.view.delete = this.data.delete ? this.data.delete['label'] ?? this.data.delete : null;
    }


    @Debounce(APP_CLICK_DEBOUNCE_TIMEOUT)
    public async onClickConfirm(): Promise<void> {
        this.data.form.updateValueAndValidity();
        this.data.form.markAllAsTouched();
        this.data.form.controlsList.forEach(c => { c.updateValueAndValidity(); c.markAllAsTouched() });

        if (this.data.confirm['validation'] && !this.data.confirm['validation'](this.data))
            return;

        if (!this.data.confirm['validation'] && this.data.form.invalid)
            return;

        this.dialogRef.close({
            form: this.data.form,
            response: 'confirm'
        });
    }

    @Debounce(APP_CLICK_DEBOUNCE_TIMEOUT)
    public async onClickDelete(): Promise<void> {
        if (this.data.delete instanceof ModalButton && this.data.delete.validation) {
            if (await this.data.delete.validation(this.data)) {
                this.dialogRef.close({
                    form: this.data.form,
                    response: 'delete'
                });
            }
        } else {
            this.dialogRef.close({
                form: this.data.form,
                response: 'delete'
            });
        }
    }

    @Debounce(APP_CLICK_DEBOUNCE_TIMEOUT)
    public async onClickCancel(): Promise<void> {
        this.dialogRef.close({
            form: this.data.form,
            response: 'cancel'
        });
    }

}
