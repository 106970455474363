<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
    <ui-form>
        <ui-form-field [controlName]='c.name' [control]='c' [form]='data.form'
            *ngFor='let c of data.form?.controlsList'>
        </ui-form-field>
    </ui-form>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button *ngIf='data.delete' (click)='onClickDelete()' color='warm'>{{view.delete}}</button>
    <button mat-button *ngIf='data.cancel' (click)='onClickCancel()'>{{view.cancel}}</button>
    <button mat-raised-button (click)='onClickConfirm()' color='primary'>{{view.confirm}}</button>

    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->

</mat-dialog-actions>
