import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizacaoResolver } from './modules/organizacao/organizacao.resolve';
import { AuthGuard } from './_guards/auth.guard';
import { CardapioGuard } from './_guards/cardapio.guard';
import { LojaGuard } from './_guards/loja.guard';
import { OrganizacaoGuard } from './_guards/organizacao.guard';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        canActivateChild: [],
        loadChildren: () => import('./modules/auth/auth.module')
            .then(m => m.AuthModule)
    },
    {
        path: ':organizacao/organizacao',
        canActivateChild: [AuthGuard, OrganizacaoGuard],
        resolve: [OrganizacaoResolver],
        loadChildren: () => import('./modules/organizacao/organizacao.module')
            .then(m => m.OrganizacaoModule)
    },
    {
        path: ':organizacao/cardapio',
        canActivateChild: [CardapioGuard],
        resolve: [OrganizacaoResolver],
        loadChildren: () => import('./modules/cardapio/cardapio.module')
            .then(m => m.CardapioModule)
    },
    {
        path: ':organizacao/loja',
        canActivateChild: [LojaGuard],
        resolve: [OrganizacaoResolver],
        loadChildren: () => import('./modules/loja/loja.module')
            .then(m => m.LojaModule)
    },
    {
        path: 'not-found',
        loadChildren: () => import('./modules/not-found/not-found.module')
            .then(m => m.NotFoundModule)
    },
    {
        path: '**',
        redirectTo: 'not-found',
        pathMatch: 'full'
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: 'disabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
