import { Inject, Injectable } from "@angular/core";
import { collection, doc, Firestore, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { Observable } from "rxjs";
import { CardapioInterface } from "src/app/domain/entities/cardapio.model";
import { CardapioRepository } from "src/app/domain/entities/cardapio.repository";
import { GuidInterface } from "src/app/domain/util/guid";
import { FirebaseCollection } from "../firebase.collection";
import { FirebaseGuid } from "../firebase.guid";
import { FirebaseService } from "../firebase.service";
import { CardapioFactory } from "./cardapio.factory";

@Injectable({ providedIn: 'root' })
export class CardapioCollection extends FirebaseCollection<CardapioInterface> implements CardapioRepository {

    private readonly ref: string = 'cardapio';
    private readonly db: Firestore;
    private readonly converter: CardapioFactory;

    constructor(
        @Inject(FirebaseService) fb: FirebaseService,
        @Inject(CardapioFactory) factory: CardapioFactory,
    ) {
        super(fb.firestore(), factory);
        this.db = fb.firestore();
        this.converter = factory;
    }


    findByAccessPoint(value: string): Observable<CardapioInterface | null> {
        return new Observable<CardapioInterface>(sub => {
            const col = collection(this.db, this.ref)
                .withConverter(this.converter);

            const q = query(col, where('accessPoint', '==', value));
            getDocs(q)
                .then(snap => {
                    if (snap.size > 1) return Promise.reject('More than one found!');
                    if (!snap.empty && snap.docs[0].exists())
                        return Promise.resolve(snap.docs[0].data());

                    return Promise.resolve(null);
                })
                .then(data => sub.next(data))
                .catch(e => sub.error(e));
        });
    }

    get(guid: GuidInterface): Observable<CardapioInterface> {
        return new Observable<CardapioInterface>(sub => {
            const id = guid.toString();
            const ref = doc(this.db, id)
                .withConverter(this.converter);

            getDoc(ref)
                .then(snap => {
                    let data = null;
                    if (snap.exists())
                        data = snap.data();
                    sub.next(data);
                })
                .catch(e => sub.error(e))
        });
    }


    async save(cardapio: CardapioInterface): Promise<void> {

        const guid = new FirebaseGuid(cardapio.id, cardapio.keys);
        const ref = doc(this.db, guid.toString())
            .withConverter(this.converter);

        await setDoc(ref, cardapio, { merge: true });
    };

    public async getAll(): Promise<CardapioInterface[]> {
        const col = collection(this.db, this.ref);
        const snaps = await getDocs(col.withConverter(this.converter));
        return snaps.docs.map(d => d.data());
    }
}
