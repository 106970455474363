import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Debounce } from 'lodash-decorators';
import { APP_CLICK_DEBOUNCE_TIMEOUT } from 'src/app/app.constants';
import { ModalListItem, ModalListOpts, ModalListResult } from '../modal.service';

@Component({
    selector: 'app-modal-list',
    templateUrl: './modal-list.component.html',
    styleUrls: ['./modal-list.component.scss']
})
export class ModalListComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ModalListComponent, ModalListResult>,
        @Inject(MAT_DIALOG_DATA) public data: ModalListOpts,
    ) { }

    ngOnInit(): void {

    }

    @Debounce(APP_CLICK_DEBOUNCE_TIMEOUT)
    public async onClickConfirm(): Promise<void> {
        this.dialogRef.close({
            list: this.data.list,
            selected: this.data.list.filter(i => i.selected),
            response: 'confirm'
        });
    }


    public async onClickCancel(): Promise<void> {
        this.dialogRef.close({
            list: this.data.list,
            selected: this.data.list.filter(i => i.selected),
            response: 'cancel'
        });
    }

    @Debounce(APP_CLICK_DEBOUNCE_TIMEOUT)
    public async onClick(i: ModalListItem): Promise<void> {
        if (i.onClick) {
            i.onClick(i);
        } else {
            i.selected = !i.selected;
        }
    }

}
