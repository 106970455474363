import { Inject, Injectable, InjectionToken } from '@angular/core';
import { FirebaseApp, initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth';
import { DocumentReference, DocumentSnapshot, Firestore, getDoc, getFirestore, runTransaction, setDoc, Transaction, WithFieldValue, writeBatch, WriteBatch } from 'firebase/firestore';
import { FirebaseConfig } from './firebase.config';

/**
 * This is not a real service, but it looks like it from the outside.
 * It's just an InjectionTToken used to import the config object, provided from the outside
 */
export const FirebaseConfigService = new InjectionToken<FirebaseConfig>('FirebaseConfig');


@Injectable({ providedIn: 'root' })
export class FirebaseService {


    private readonly app: FirebaseApp;
    private transaction: FirebaseTransaction | null = null;
    private batch: FirebaseBatch | null = null;

    constructor(@Inject(FirebaseConfigService) config: any) {
        this.app = initializeApp(config);
    }

    public auth() {
        return getAuth(this.app);
    }

    public firestore() {
        return getFirestore(this.app);
    }

    public storage() {
        return getStorage(this.app);
    }


    public getConnection(): FirebaseConnection {
        return this.batch ?? this.transaction ?? new FirebaseTransaction(this.firestore());
    }


    private begginTransaction(): FirebaseTransaction {
        const con = new FirebaseTransaction(this.firestore());
        con.begginTransaction();
        this.transaction = con;
        return this.transaction;
    }


    async runTransaction<T>(arg0: () => Promise<T>) {
        const trans = this.begginTransaction();
        try {
            const result = await arg0();
            trans.commit();
            return result;
        } catch (err) {
            trans.roolback();
            throw err;
        } finally {
            trans.endTransaction();
        }
    }

    async runBatch<T>(arg0: () => Promise<T>) {
        this.batch = new FirebaseBatch(this.firestore());
        try {
            const result = await arg0();
            await this.batch.commit();
            return result;
        } catch (err) {
            throw err;
        } finally {
            this.batch = null;
        }
    }
}

export interface FirebaseConnection {

    get<T>(documentRef: DocumentReference<T>): Promise<DocumentSnapshot<T>>;
    set<T>(documentRef: DocumentReference<T>, data: WithFieldValue<T>): Promise<void> | void;
    commit(): Promise<void> | void;
}


export class FirebaseBatch implements FirebaseConnection {

    private batchs: WriteBatch[] = [];
    private counter: number = 0;

    constructor(private firestore: Firestore) {
        this.batchs.push(writeBatch(firestore));
    }

    async get<T>(documentRef: DocumentReference<T>): Promise<DocumentSnapshot<T>> {
        return getDoc<T>(documentRef);
    }

    public set<T>(documentRef: DocumentReference<T>, data: WithFieldValue<T>): Promise<void> {
        this.getBatch().set(documentRef, data);
        return Promise.resolve();
    }


    public async commit(): Promise<void> {
        await Promise.all(this.batchs.map(b => b.commit()));
    }

    private getBatch(): WriteBatch {
        if (++this.counter >= 500) {
            this.batchs.push(writeBatch(this.firestore));
            this.counter = 0;
        }
        return this.batchs[this.batchs.length - 1];
    }
}



export class FirebaseTransaction implements FirebaseConnection {

    private transaction: Transaction | null = null;
    constructor(private firestore: Firestore) { }

    set<T>(documentRef: DocumentReference<T>, data: WithFieldValue<T>): Promise<void> | void {
        if (this.transaction) {
            this.transaction = this.transaction.set(documentRef, data);
            return;
        }
        return setDoc(documentRef, data);
    }

    async get<T>(documentRef: DocumentReference<T>): Promise<DocumentSnapshot<T>> {
        if (this.transaction) {
            return this.transaction.get(documentRef);
        }
        return getDoc<T>(documentRef);
    }


    public begginTransaction() {
        runTransaction(this.firestore, async (transaction) => {
            this.transaction = transaction;
            await this.awaitForCommit();
        });
    }

    public commit(): void {
        if (!this._commit)
            throw "Transaction not initialized";
        this._commit();
        this.endTransaction();
    }

    public roolback(): void {
        if (!this._roolback)
            throw "Transaction not initialized";

        this._roolback();
        this.endTransaction();
    }

    public endTransaction() {
        this._commit = null;
        this._roolback = null;
    }

    private _commit: (() => void) | null = null;
    private _roolback: (() => void) | null = null;

    private async awaitForCommit(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._commit = resolve;
            this._roolback = reject;
        });
    }

}

