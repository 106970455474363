import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'join'
})
export class JoinPipe implements PipeTransform {

    transform(value: Array<any>, sep: string, field?: string): unknown {
        if (field) {
            value = value.map(a => a[field]);
        }
        if (sep === 'text' && value.length > 1) {
            return `${value.slice(0, value.length - 1).join(', ')} e ${value.last()}`;
        }
        return value.join(sep);
    }

}


@NgModule({
    declarations: [JoinPipe],
    imports: [CommonModule],
    exports: [JoinPipe],
    providers: [JoinPipe]
})
export class JoinPipeModule { }
