import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalLoadingComponent } from '../modal-loading/modal-loading.component';
import { ModalLoadingOpts } from '../modal.service';
import { ModalMessage, ModalMessageOpts } from './modal-message.interface';

@Component({
    selector: 'app-modal-message',
    templateUrl: './modal-message.component.html',
    styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent implements ModalMessage, OnInit {

    public message: string;
    public formatedText: string;
    public title: string;
    public closeBtnLabel: string;

    constructor(
        private dialogRef: MatDialogRef<ModalMessageComponent>,
        @Inject(MAT_DIALOG_DATA) private data: ModalMessageOpts,
    ) {
        this.title = data?.title;
        this.message = data?.message;
        this.formatedText = data?.formatedText;
        this.closeBtnLabel = data?.closeBtnLabel ?? 'Fechar';
    }

    ngOnInit(): void {

    }

    close(): void {
        this.dialogRef?.close();
    }


    update(opts: ModalMessageOpts): this {
        this.title = opts.title ?? this.title;
        this.message = opts.message ?? this.message;
        this.closeBtnLabel = opts.closeBtnLabel ?? this.closeBtnLabel;
        return this;
    }

    waitingForClose(): Promise<void> {
        return new Promise<void>(resolve => this.dialogRef?.afterClosed().subscribe(() => resolve()));
    }

}
