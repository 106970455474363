import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BrowserCodeReader, BrowserMultiFormatReader, IScannerControls } from '@zxing/browser';
import { Result } from '@zxing/library';
import { Observable, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QrCodeReaderService {

    constructor(
        private dialog: MatDialog
    ) {

    }


    async isAvaliable(): Promise<boolean> {
        const devices = await this.devices();
        return devices?.length > 0;
    }

    async permission(): Promise<PermissionState> {
        const permission = await navigator.permissions.query({ name: 'camera' } as any);
        return permission.state;
    }


    async devices(): Promise<MediaDeviceInfo[]> {
        return BrowserCodeReader.listVideoInputDevices();
    }

    async reader(): Promise<CodeReader> {
        const devices = await this.devices();
        return new CodeReader(devices);
    }

}


export class ScannerControl {

    private emiter: EventEmitter<CodeReaderResult> = new EventEmitter();
    private controls: IScannerControls[];

    constructor(
        readonly media: MediaDeviceInfo,
        private readers: BrowserCodeReader[],
        private preview?: ElementRef
    ) { }

    private decode(r: BrowserCodeReader, handle: (r, e) => void): Promise<IScannerControls> {
        return r.decodeFromVideoDevice(this.media.deviceId, this.preview.nativeElement, handle);
    }

    async start(): Promise<this> {
        this.controls =
            await Promise.all(
                this.readers
                    .map(r => this.decode(r, (result, error) => this.emiter.emit({ result, error })))
            );
        return this;
    }


    stop(): void {
        this.controls?.notNull().map(c => c.stop());
        this.controls = null;
    }

    torch(enable: boolean): void {
        const ctrl = this.controls.first();
        if (ctrl && ctrl.switchTorch) {
            ctrl.switchTorch(enable);
        }
    }

    observable(): Observable<CodeReaderResult> {
        return this.emiter.asObservable();
    }


}

export interface CodeReaderResult {
    result: Result;
    error?: Error;
}

export class CodeReader {

    private emiter: EventEmitter<CodeReaderResult> = new EventEmitter();
    private controls: ScannerControl[] = [];

    private subs: Subscription[] = [];
    constructor(readonly devices: MediaDeviceInfo[] = []) { }

    observe(): Observable<CodeReaderResult> {
        return this.emiter.asObservable();
    }


    scanner(opt?: { deviceId?: string, preview?: ElementRef }): ScannerControl[] {
        const readers = [
            new BrowserMultiFormatReader(),
        ];

        const devices = opt?.deviceId && this.devices.filter(d => d.deviceId === opt?.deviceId) || this.devices;
        if (!devices) { throw new Error('device not found'); }

        const scanner = devices.map(d => new ScannerControl(d, readers, opt?.preview));
        this.subs = scanner.map(s => s.observable().subscribe(r => this.emiter.next(r)));
        this.controls.addRange(scanner);
        return scanner;
    }
}
