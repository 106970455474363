import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from '@angular/material/select';
import { NgxUiModule } from '@azoup/ngx-ui';

import { ClipboardDirective } from './_directives/clipboard.directive';
import { TouchDirective } from './_directives/touch.directive';
import { BlankPageComponent } from './blank-page/blank-page.component';
import { CarouselComponent } from './carousel/carousel.component';
import { IncrementButtonModule } from './increment-button/increment-button.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { LoadingTileComponent } from './loading-tile/loading-tile.component';
import { MaterialModule } from './material.module';
import { ModalConfirmComponent } from './modal/modal-confirm/modal-confirm.component';
import { ModalFormComponent } from './modal/modal-form/modal-form.component';
import { ModalListComponent } from './modal/modal-list/modal-list.component';
import { ModalLoadingComponent } from './modal/modal-loading/modal-loading.component';
import { ModalMessageComponent } from './modal/modal-message/modal-message.component';
import { ModalProgressBarComponent } from './modal/modal-progress-bar/modal-progress-bar.component';
import { FlagPipeModule } from './pipes/flag.pipe';
import { JoinPipeModule } from './pipes/join.pipe';
import { SimNaoPipe } from './pipes/sim-nao.pipe';
import { QrCodePromptDialogComponent, QrcodeReaderComponent } from './qrcode-reader/qrcode-reader.component';
import { ScrollerDirective } from './scroller/scroller.directive';
import { WINDOW_PROVIDERS } from './window';
import { ModalExpansionListComponent } from './modal/modal-expansion-list/modal-expansion-list.component';

const components = [
    BlankPageComponent,
    CarouselComponent,
    LoadingPageComponent,
    LoadingTileComponent,
    QrcodeReaderComponent,
    QrCodePromptDialogComponent,
    ModalConfirmComponent,
    ModalFormComponent,
    ModalListComponent,
    ModalExpansionListComponent,
    ModalLoadingComponent,
    ModalMessageComponent,
    ModalProgressBarComponent,
]

const pipes = [
    SimNaoPipe,
]
const directives = [
    ScrollerDirective,
    ClipboardDirective,
    TouchDirective,
];

@NgModule({
    declarations: [
        ...components,
        ...directives,
        ...pipes,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        NgxUiModule,
        JoinPipeModule,
        FlagPipeModule,
        IncrementButtonModule,
    ],
    exports: [
        ...components,
        ...directives,
        ...pipes,
        MaterialModule,
        NgxUiModule,
        SimNaoPipe,
        ScrollerDirective,
        ClipboardDirective,
        IncrementButtonModule,
        JoinPipeModule,
        IncrementButtonModule,
    ],
    providers: [
        WINDOW_PROVIDERS, ,
        MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
        MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
        ...directives
    ]
})
export class UiComponentsModule { }
