import { NgxFirebaseService } from '@azoup/ngx-firebase';
import { UsuarioRolesType } from '@azoup/ngx-firebase-auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrganizacaoDoc } from 'src/app/_firebase/organizacao/organizacao.doc';
import { OrganizacaoUsuarioRolesType } from 'src/app/_firebase/organizacao/usuario/organizacao-usuario-roles.type';
import { OrganizacaoUsuarioDoc } from 'src/app/_firebase/organizacao/usuario/organizacao-usuario.doc';
import { UsuarioDoc } from 'src/app/_firebase/usuario/usuario.doc';


export type TOrganizacaoPerfil = 'gestor' | 'profissional' | 'cliente' | 'no user' | 'system';

export type TOrgUsuarioRoles = UsuarioRolesType & OrganizacaoUsuarioRolesType;

export const RolesDefault: TOrgUsuarioRoles = {
    ADMIN: false,
    cliente: false,
    gestor: false,
    profissional: false,
    vendedor: false,
};

export interface ICurrentUser {
    user: UsuarioDoc;
    organizacaoUser: OrganizacaoUsuarioDoc;
    organizacao: OrganizacaoDoc;
    roles: () => UsuarioRolesType & OrganizacaoUsuarioRolesType;
    clear: () => void;
}


export class CurrentUser implements ICurrentUser {
    private _status: BehaviorSubject<CurrentUser>;
    private _firebase: NgxFirebaseService;

    private _userDoc: UsuarioDoc;
    private _organizacaoUserDoc: OrganizacaoUsuarioDoc;
    private _organizacaoDoc: OrganizacaoDoc;


    get status(): Observable<CurrentUser> {
        return this._status.asObservable();
    }


    set user(user: UsuarioDoc) {
        if (this._userDoc) {
            this._userDoc.offLine();
        }
        this._userDoc = user;
        if (this.user) {
            this.user.onLine();
        }

        this.emit();
    }

    get user(): UsuarioDoc {
        return this._userDoc;
    }

    set organizacaoUser(organizacaoUser: OrganizacaoUsuarioDoc) {
        if (this._organizacaoUserDoc) {
            this._organizacaoUserDoc.offLine();
        }

        this._organizacaoUserDoc = organizacaoUser;

        if (this.organizacaoUser) {
            this.organizacaoUser.data.ultimoAcesso = this._firebase.serverTimestamp();
            this.organizacaoUser.onLine();
            this.organizacaoUser.save();
        }
        this.emit();
    }

    get organizacaoUser(): OrganizacaoUsuarioDoc {
        return this._organizacaoUserDoc;
    }

    set organizacao(organizacao: OrganizacaoDoc) {
        if (this._organizacaoDoc) {
            this._organizacaoDoc.offLine();
        }

        this._organizacaoDoc = organizacao;
        if (this.organizacao) {
            this.organizacao.onLine();
        }
        this.emit();
    }

    get organizacao(): OrganizacaoDoc {
        return this._organizacaoDoc;
    }

    get perfil(): TOrganizacaoPerfil {
        if (!this.organizacaoUser) {
            return 'no user';
        }
        return this.organizacaoUser.data.roles.gestor ? 'gestor' :
            this.organizacaoUser.data.roles.profissional ? 'profissional' :
                this.organizacaoUser.data.roles.cliente ? 'cliente' :
                    null;
    }

    constructor(
        firebase: NgxFirebaseService
    ) {
        this._firebase = firebase;
        this._status = new BehaviorSubject<CurrentUser>(null);
    }

    roles(): TOrgUsuarioRoles {
        const roles = Object.assign({}, RolesDefault);
        if (!this.user) { return roles; }
        Object.keys(this.user.data.roles || {}).map(k => roles[k] = this.user.data.roles[k]);
        if (!this.organizacaoUser) { return roles; }
        Object.keys(this.organizacaoUser.data.roles || {}).map(k => roles[k] = this.organizacaoUser.data.roles[k]);
        return roles;
    }

    clear(): void {
        if (this.user) { this.user.offLine(); }
        if (this.organizacao) { this.organizacao.offLine(); }
        if (this.organizacaoUser) { this.organizacaoUser.offLine(); }
        this.user = undefined;
        this.organizacaoUser = undefined;
        this.organizacao = undefined;
    }

    emit(): void {
        this._status.next(this);
    }

}
