import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FirebaseConfig } from './firebase.config';
import { FirebaseConfigService, FirebaseService } from './firebase.service';

export * from "./auth/firebase-auth.service";

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ]
})
export class FirebaseModule {
    static forRoot(config: FirebaseConfig): ModuleWithProviders<FirebaseModule> {
        return {
            ngModule: FirebaseModule,
            providers: [
                { provide: FirebaseConfigService, useValue: config },
                FirebaseService,
            ]
        };
    }
}
