import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { setLogLevel } from "firebase/app";
import { firstValueFrom } from "rxjs";
import { Log } from "src/app/domain/entities/log/log.model";
import { LogRepository } from "src/app/domain/entities/log/log.repository";
import { environment } from "src/environments/environment";


export type Result<T> = {
    data: T[],
    itens: number,
    page: number,
    total: number,
}

export type LogBody = {
    created: string,
    mensagem: string,
    level: number,
    levelNome: string,
    eventoId: number,
    eventoNome: string,
    registro: string,
    organizacao: string,
    codigoApi: number,
    aplicacao: string,
    aplicacaoVersao: string,
    md5: string
}

@Injectable({ providedIn: 'root' })
export class LogApiRepository extends LogRepository {

    constructor(
        private readonly http: HttpClient
    ) {
        super();
    }

    async save(log: Log): Promise<void> {
        const url = `${environment.log.url}/api/log`;
        const body: LogBody = {
            created: new Date().toUTCString(),
            mensagem: log.message,
            level: log.levelCode(),
            levelNome: log.level,
            eventoId: 0,
            eventoNome: log.evento,
            registro: log.registro,
            organizacao: log.organizacao,
            codigoApi: null,
            aplicacao: log.aplicacaoNome,
            aplicacaoVersao: log.aplicacaoVersao,
            md5: log.hash,
        };
        await firstValueFrom(this.http.post(url, body))
            .catch(e => {
                console.error(e);
                return Promise.reject(e);
            })
    }


}



