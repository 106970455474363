import { CommonModule } from "@angular/common";
import { ErrorHandler, NgModule } from "@angular/core";
import { GlobalErrorHandler } from "./erros/global-error-handler";

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        {
            // processes all errors
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
    ],
})
export class CoreModule { }
