import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OrganizacaoCollection } from 'src/app/_firebase/organizacao/organizacao.collection';
import { AuthService } from '../modules/auth/auth.service';
import { ModalService } from '../ui-components/modal/modal.service';


@Injectable({
    providedIn: 'root'
})
export class OrganizacaoGuard implements CanActivate, CanActivateChild {

    constructor(
        private organizacaoCollection: OrganizacaoCollection,
        private auth: AuthService,
        private modal: ModalService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.check(route, state);
    }
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.check(childRoute, state);
    }


    private async check(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        const id = next.pathFromRoot.find(p => p.params.organizacao)?.params.organizacao ?? null
        if (!id) {
            return this.router.createUrlTree(state.url.split('/').slice(0, -1), { relativeTo: this.route });
        }

        const org = await this.organizacaoCollection.get(id);
        if (!org) {
            this.modal.openMessage({
                title: 'Não encontado!',
                message: `Organização "${id}" não foi encontada.`,
            });

            return this.router.createUrlTree([state.url.split('/').slice(0, -1)], { relativeTo: this.route });
        }

        const roles = this.auth.currentUser.roles();
        if (roles?.ADMIN) return true;
        if (roles?.gestor) return true;

        const email = this.auth.currentUser.user.data.email;
        const usuarios = await this.organizacaoCollection.getUsuariosFromCollectionGroupUsuarios({ email });
        const found = usuarios.find(u => u.ref.parent.parent.id === id);
        if (!!found) {
            this.auth.currentUser.organizacaoUser = found;
            this.auth.currentUser.organizacao = org;

            const roles = this.auth.currentUser.roles();
            if (roles?.ADMIN) return true;
            if (roles?.gestor) return true;
             await this.modal.openMessage({
                title: 'Acesso Restrito!',
                message: `Usuario "${email}" não tem permissão de acesso a esta organização "${id}"`,
            });

            await this.auth.logout();
            return this.router.createUrlTree(['..'], { relativeTo: this.route });
        }

        await this.modal.openMessage({
            title: 'Acesso Restrito!',
            message: `Usuario "${email}" não pertence a esta organização "${id}"`,
        });
        await this.auth.logout();
        return this.router.createUrlTree(['..'], { relativeTo: this.route });

    }
}
