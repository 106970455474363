import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalProgressBar, ModalProgressBarColor, ModalProgressBarMode, ModalProgressBarOpts } from './modal-progress-bar.interface';


@Component({
    selector: 'app-modal-progress-bar',
    templateUrl: './modal-progress-bar.component.html',
    styleUrls: ['./modal-progress-bar.component.scss'],
    host: { class: 'modal-progress-bar' }
})
export class ModalProgressBarComponent implements ModalProgressBar, OnInit {

    public mode: ModalProgressBarMode;
    public color: ModalProgressBarColor;
    public value: number;
    public message: string;
    public title: string;
    public estimative: number;
    public closeBtnShow: boolean;
    public closeBtnLabel: string;
    public closeBtnDisabled: boolean;

    private timeout;

    constructor(
        private dialogRef: MatDialogRef<ModalProgressBarComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ModalProgressBarOpts,
    ) {
        this.mode = data?.mode ?? 'indeterminate';
        this.color = data?.color ?? 'primary';
        this.value = data?.value ?? 0;
        this.message = data?.message ?? null;
        this.title = data?.title ?? '#cestou';
        this.closeBtnShow = data?.closeBtn?.show ?? false;
        this.closeBtnLabel = data?.closeBtn?.label ?? 'Fechar';
        this.closeBtnDisabled = data?.closeBtn?.disabled ?? false;
    }

    ngOnInit(): void {
    }

    public close(): this {
        this.stopProgress();
        this.dialogRef?.close();
        return this;
    }

    public startProgress(timeout: number): this {
        this.stopProgress();
        this.value = 0;
        this.mode = 'determinate';
        const end = Date.now() + timeout;

        const start = () => {
            const diff = end - Date.now();
            this.estimative = Math.floor(diff / 1000);
            this.value = ((((diff / timeout) * 100) - 100) * -1);

            this.timeout = setTimeout(() => {
                if (Date.now() <= end) {
                    start();
                } else {
                    this.value = 100;
                    this.estimative = 0;
                }
            }, 40);
        };
        start();
        return this;
    }

    public stopProgress(): this {
        this.estimative = 0;
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        return this;
    }

    public completeProgress(): this {
        this.stopProgress();
        this.update({ mode: 'determinate', value: 100 });
        return this;
    }

    public complete(opts: ModalProgressBarOpts = {}): this {
        this.stopProgress();
        this.update({ ...opts, mode: 'determinate', value: 100 });
        return this;
    }


    public waitingForClose(): Promise<void> {
        return new Promise<void>(resolve => this.dialogRef.afterClosed().subscribe(() => resolve()));
    }


    public update(opts: ModalProgressBarOpts): this {
        this.mode = opts.mode ?? this.mode;
        this.color = opts.color ?? this.color;
        this.value = opts.value ?? this.value;
        this.message = opts.message ?? this.message;
        this.title = opts.title ?? this.title;
        this.closeBtnShow = opts.closeBtn?.show ?? this.closeBtnShow;
        this.closeBtnLabel = opts.closeBtn?.label ?? this.closeBtnLabel;
        this.closeBtnDisabled = opts.closeBtn?.disabled ?? this.closeBtnDisabled;
        return this;
    }

}
