import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'ui-increment-button',
    templateUrl: './increment-button.component.html',
    styleUrls: ['./increment-button.component.scss']
})
export class IncrementButtonComponent implements OnInit {

    @Input('zerable') zerable = false;
    @Input('step') stepSize = 1;
    @Input('min') minSize = 0;
    @Input('max') maxSize = 999;
    @Input('size') size = 0;
    @Input('sufix') sufix;
    @Input('disabled') disabled = false;

    @Output('sizeChange') sizeChange = new EventEmitter<number>();


    constructor() { }

    ngOnInit(): void {
        this.resize(0);
    }

    dec(): void { this.resize(-this.stepSize); }
    inc() { this.resize(+this.stepSize); }

    resize(delta: number) {
        if (this.zerable && delta < 0 && Math.round10(this.size + delta, -2) < this.minSize) {
            this.size = 0;
            this.sizeChange.emit(this.size);
        } else {
            this.size = Math.round10(Math.min(this.maxSize, Math.max(this.minSize, +this.size + delta)), -2);
            this.sizeChange.emit(this.size);
        }
    }
}

@NgModule({
    declarations: [IncrementButtonComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
    ],
    exports: [IncrementButtonComponent],
})
export class IncrementButtonModule { }
