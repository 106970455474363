import { Injectable } from "@angular/core";
import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { Loja, LojaAttr } from "src/app/domain/entities/loja.model";
import { Guid } from "src/app/domain/util/guid";
import { notUndefined } from "src/app/domain/util/not-undefined";

import { LojaGuid } from "./loja.guid";
import { LojaSchema } from "./loja.schema";
import { mode } from "crypto-js";


@Injectable({ providedIn: 'root' })
export class LojaFactory implements FirestoreDataConverter<Loja> {
    toFirestore(model: Loja): DocumentData {
        const data: LojaSchema = {
            id: model.id,
            accessPoint: model.accessPoint,
            areaDeAtendimento: {
                coordenadas: model.areaDeAtendimento.coordenadas ?? [],
                enabled: model.areaDeAtendimento.enabled ?? false
            },
            cpfHabilitado: model.cpfHabilitado,
            cpfObrigatorio: model.cpfObrigatorio,
            enabled: model.enabled,
            organizacaoId: model.organizacaoId,
            entregaHabilitado: model.entregaHabilitado ?? true,
            entregaValorMinimo: model.entregaValorMinimo,
            horarios: model.horarios ?? [],
            fretes: model.fretes ?? [],
            utilizarHorarioDeFuncionamento: model.utilizarHorarioDeFuncionamento,
            retiradaHabilitado: model.retiradaHabilitado ?? true,
            retiradaCusto: model.retiradaCusto,
            retiradaTempo: model.retiradaTempo,
            coordenadas: model.coordenadas,
            utilizarEstoque: model.utilizarEstoque,
            enviarPedidoPeloWhats: model.enviarPedidoPeloWhats ?? true,
        };
        return notUndefined(data);
    }
    fromFirestore(snapshot: QueryDocumentSnapshot<LojaSchema>, options?: SnapshotOptions | undefined): Loja {
        const data = snapshot.data(options);
        const attr = notUndefined<LojaAttr>({
            organizacaoId: data.organizacaoId,
            enviarPedidoPeloWhats: data.enviarPedidoPeloWhats ?? true,
            accessPoint: data.accessPoint,
            areaDeAtentimento: {
                coordenadas: data.areaDeAtendimento?.coordenadas ??[],
                enabled: data.areaDeAtendimento?.enabled ?? false,
            },
            cpfHabilitado: data.cpfHabilitado,
            cpfObrigatorio: data.cpfObrigatorio,
            enabled: data.enabled,
            entregaHabilitado: data.entregaHabilitado ?? true,
            entregaValorMinimo: data.entregaValorMinimo,
            utilizarHorarioDeFuncionamento: data.utilizarHorarioDeFuncionamento,
            horarios: data.horarios,
            fretes: data.fretes,
            retiradaHabilitado: data.retiradaHabilitado ?? true,
            retiradaCusto: data.retiradaCusto,
            retiradaTempo: data.retiradaTempo,
            utilizarEstoque: data.utilizarEstoque ?? false,
            coordenadas: data.coordenadas ? {
                latitude: data.coordenadas.latitude,
                longitude: data.coordenadas.longitude,
            } : null,
        }, null);
        const guid: Guid = new LojaGuid(snapshot.id)
        return new Loja(attr, guid);
    }
}
