import { Directive, ElementRef, HostListener, Input, ViewContainerRef } from '@angular/core';
import { Debounce, Throttle } from 'lodash-decorators';
import { env } from 'process';

@Directive({
  selector: '[scroller]'
})
export class ScrollerDirective {

  private isDown: boolean;
  private scrollLeft: number;
  private scrollTop: number;
  private container: HTMLElement;

  constructor(viewContainer: ViewContainerRef) {
    this.container = viewContainer.element.nativeElement;
  }


  @HostListener('mousedown', ['$event'])
  down(event) {
    this.isDown = true;
    this.scrollLeft = this.container.scrollLeft;
    this.scrollTop = this.container.scrollTop;
  }

  @HostListener('mouseenter', ['$event'])
  @HostListener('mouseleave', ['$event'])
  @HostListener('mouseup', ['$event'])
  up(event) {
    this.isDown = false;
  }

  @HostListener('mousemove', ['$event'])
  move(event) {
    if (!this.isDown) { return; }
    this.scrollLeft = this.scrollLeft + (event.movementX * -1);
    this.scrollTop = this.scrollTop + (event.movementY * -1);
    this.updateScroll();
  }


  private updateScroll() {
    const top = this.scrollTop;
    const left = this.scrollLeft;
    this.container.scrollTo({ top, left });
  }

}
